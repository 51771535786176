@import "modules/common/components/variables.scss";

.survey {
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  flex: 1 auto;
  width: 50%;
  background-color: $standard-planr-gray-color;
  :global {
    .block-header {
      padding-left: 10px;
      padding-right: 10px;
    }
    .bp3-icon-cross {
      position: absolute;
      top: 10px;
      right: 5px;
    }
  }
}

.surveyEnded {
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  width: 50%;
  background-color: #e4e8eb;
  :global {
    .block-header {
      padding-left: 10px;
      padding-right: 10px;
    }
    .bp3-icon-cross {
      position: absolute;
      top: 10px;
      right: 5px;
    }
  }
}

.header {
  background-color: transparent;
  display: flex;
  border: transparent;
  font-size: 11px;
  color: rgba(0, 39, 61, 0.4);
  .status {
    text-transform: uppercase;
    margin-left: auto;
    font-weight: 700;
    &.inactive {
      color: rgba(0, 39, 61, 0.4);
    }

    &.active {
      color: #1dd278;
    }
  }
}
.surveyTop {
  display: flex;
  padding-left: 8px;
  padding-right: 10px;
  padding-bottom: 8px;
  color: $standard-planr-darkblue;
  position: relative;
  font-weight: 400;
  font-size: 12px;
  .surveyEndIcon {
    position: absolute;
    top: 0px;
    right: 16px;
  }
  .surveyName {
    color: $standard-planr-darkblue;
  }
}
.body {
  border-radius: 4px;
  background-color: #fff;
  min-height: 160px;
  flex: 1 1;
  position: relative;
  color: $standard-planr-darkblue;
  font-weight: 300;
  font-size: 14px;
  width: 162px;
  padding: 5px;
  margin-top: 10px;
  margin-left: 12px;
}

.bodyEnded {
  border-radius: 4px;
  background-color: #e4e8eb;
  min-height: 160px;
  flex: 1 1;
  position: relative;
  color: $standard-planr-darkblue;
  font-weight: 300;
  font-size: 14px;
  width: 162px;
  padding: 5px;
  margin-left: 12px;
  margin-top: 10px;
}

.footer {
  color: rgba(0, 39, 61, 0.5);
  font-size: 11px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 5px;
  height: 70px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  .peopleCount {
    margin-left: 5px;
  }
}

.result {
  font-size: $font-size-small;

  .stripe {
    margin-top: 7px;
  }

  .progress {
    position: relative;

    .percents {
      position: absolute;
      top: -$font-size-standard;
    }
  }

  :global {
    .bp3-progress-bar .bp3-progress-meter {
      background-color: #ea561e;
    }
  }
}

.form {
  label {
    font-size: $font-size-small !important;
  }

  .variant:first-of-type {
    margin-top: 7px;
  }
}
