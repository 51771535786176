@import "./common";

@media (min-width: 885px) {
  .row {
    transform: scale(0.94);
    transform-origin: left;
    padding-left: $c-padding;
    margin-left: 11px;
    margin-right: 8px;
    margin-top: -30px;
    :global {
      @import "../../../spending/timesheet/components/row";

      .timesheet-user-name {
        display: none;
      }
    }
  }
}

@media (max-width: 885px) {
  .row {
    transform: scale(1.4);
    transform-origin: left;
    /* padding-left: 5px; */
    margin-left: 11px;
    margin-right: 8px;
    margin-top: 10px;
    margin-bottom: 20px;
    width: 67%;
    overflow-x: auto;
    :global {
      @import "../../../spending/timesheet/components/row";

      .timesheet-user-name {
        display: none;
      }
    }
  }
}
