@import "./_common.scss";

.dialog {
  width: 1074px;
  min-height: 480px;

  form {
    flex: 1 1;
    display: flex;
    flex-direction: column;
  }
}
.smallDialog {
  width: 420px;
  height: 230px;
}
.clearInfo {
  color: $standard-planr-darkblue;
}
.dayType,
.projectSelection {
  max-width: 100%;
}
.commentDay {
  margin-bottom: 16px;
}
.dayType {
  display: flex;
  align-items: flex-end;

  :global {
    .select {
      flex: 1 1;
    }

    .figma-icon {
      margin-right: $standard-padding;
    }
  }
}

.activeIcon {
  path {
    fill: $standard-blue !important;
  }
}

.projectSelection {
  margin-top: $standard-padding;

  .justWork {
    width: 100%;
  }
}

.divider {
  width: 100%;
  height: 1px;
  border-bottom: 2px solid $standard-planr-gray-color;
  margin-top: $standard-padding;
  margin-bottom: $standard-padding;
}

.dayResult {
  .projectRow {
    color: $standard-planr-darkblue;
    font-family: GothamPro, Arial, sans-serif;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;

    :global {
      .bp3-icon-cross {
        margin-left: 5px;
        margin-top: 2px;
      }
    }
  }
  .projectRows {
    padding: 8px;
    border: 2px dashed $standard-planr-gray-color;
    border-radius: 4px;
    margin-bottom: 15px;
  }

  .projectName {
    margin-right: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  .home {
    margin-left: 10px;
    cursor: pointer;
    min-width: 22px;

    &.invisible {
      visibility: hidden;
    }
  }
}

.selectedIcon {
  path {
    fill: $standard-blue !important;
  }
}
.noWorkInfo {
  position: absolute;
  color: #e31818;
  font-size: 10px;
  left: 110px;
  //  top: 5px;
}
.hoursInput {
  max-width: 50px;
  min-width: 50px;
  margin-left: $standard-padding;
  margin-right: 5px;

  input[disabled] {
    color: $black;
    cursor: default;
  }
}

.commentInput {
  margin: 0 auto;
  margin-top: 5px;
  input[disabled] {
    color: $black;
    cursor: default;
  }
}

@media (min-width: 885px) {
  :global {
    .timesheet-task-select-dropdown {
      width: 592px !important;

      .bp3-fill {
        word-wrap: break-word;
        overflow: initial;
        text-overflow: initial;
        white-space: normal;
        text-align: justify;
      }
    }

    .timesheet-task-select-dropdown-long {
      width: 712px !important;

      .bp3-fill {
        word-wrap: break-word;
        overflow: initial;
        text-overflow: initial;
        white-space: normal;
        text-align: justify;
      }
    }
  }
}

@media (max-width: 885px) {
  :global {
    .timesheet-task-select-dropdown {
      .bp3-fill {
        word-wrap: break-word;
        overflow: initial;
        text-overflow: initial;
        white-space: normal;
        text-align: justify;
      }
    }

    .timesheet-task-select-dropdown-long {
      .bp3-fill {
        word-wrap: break-word;
        overflow: initial;
        text-overflow: initial;
        white-space: normal;
        text-align: justify;
      }
    }
  }
}
