@import "modules/common/components/variables.scss";
@import "modules/root/styles/theme/blueprint/variables.scss";
@import "modules/root/styles/planr/variables.scss";

$name: 340px;

$cell: 46px;
$border: 2px solid #dddddd;

$weekend: rgba(175, 175, 175, 0.24);
$weekend-hover: #d7d9db;
$hover-color: $planr-lightblue-color-light;

$weekend-color: #fff;
$font-size: $font-size-small;
$missing-color: rgba(215, 54, 54, 0.3);
$success-color: rgba(2, 218, 110, 0.3);
$half-success-color: rgba(105, 219, 162, 0.3);

$today-border: 3px solid #369cd7;
$today-border-radius: 4px;
