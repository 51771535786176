@import "modules/root/styles/theme/blueprint/variables.scss";

.list {
  width: 1830px;

  &.short {
    width: 1180px;
  }

  :global {
    .bp3-dialog-body {
      display: flex;
      margin-top: 0;
      justify-content: space-between;
    }

    .ag-theme-balham {
      height: 780px;
      max-height: 780px;
      display: flex;
      flex: 1 1;
      overflow: hidden;
      flex-direction: column;
    }

    .ag-floating-bottom .bp3-icon {
      display: none;
    }
  }
}

.date {
  $space: 5px;

  display: flex;
  width: 100px;
  align-items: center;

  .button {
    margin-left: auto;
    cursor: pointer;
    color: $gray1;
    margin-right: $space;
  }

  .value {
    margin-left: $space;
  }

  :global {
    .bp3-input {
      height: 18px;
      border-radius: 0;
      box-shadow: none;
      margin-right: $space;
      padding-left: $space;
      font-size: 13px;
    }

    .bp3-input-action {
      display: none;
    }

    .bp3-input-group {
      display: flex;
    }
  }
}
.leftCol {
  display: flex;
  flex-direction: column;
  max-width: calc(52%);
  height: 780px;
  max-height: 780px;
}

.rightCol {
  display: flex;
  flex-direction: column;
  max-width: calc(50% - 30px);
  height: 780px;
  max-height: 780px;
}
