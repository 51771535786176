@import "./_common.scss";
@import "modules/root/styles/planr/variables.scss";

.cell {
  height: $cell;
  width: 40px;
  max-width: 40px;
  max-height: $cell;
  font-size: 0;
  border-right: $border;
  border-bottom: $border;
  user-select: none;
  position: relative;
  cursor: pointer;
  background-color: #fff;
  &.preToday {
    border-right-color: transparent;
  }

  &.today {
    border-right: $today-border !important;
    border-left: $today-border !important;
  }

  &:hover {
    background-color: $hover-color;
  }
}

.overlay {
  $brd: 3px;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  &.selected {
    z-index: 2;
    border: $brd solid #ea561e;
    border-radius: 4px;
    top: -($brd - 1px);
    left: -($brd - 1px);
    height: $cell + ($brd - 1px);
    width: 40px + ($brd - 1px);
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.25);
    &.today {
      width: 40px;
      left: -$brd;
    }
  }

  &.weekend {
    background-color: $weekend;

    // &:hover {
    //   background-color: $weekend-hover;
    // }
  }

  &.hoursMode {
    &.halfSuccess {
      background-color: $half-success-color;
    }

    &.success {
      background-color: $success-color;
    }

    &.missing {
      background-color: $missing-color;
    }

    &.vacation {
      background-color: rgba(125, 54, 215, 0.3);
    }

    &.illness {
      background-color: rgba(43, 150, 238, 0.35);
    }

    &.other {
      background-color: rgba(255, 255, 0, 0.384);
    }
  }

  :global {
    .bp3-icon {
      opacity: 0.095;
    }
  }
}

.short,
.hours,
.project {
  height: 50%;
  line-height: $cell / 2;
}

.short {
  display: inline-block;
  width: 50%;
  border-bottom: $border;
  font-size: $font-size;
  padding-right: 3px;
  overflow: hidden;

  &:first-child {
    border-right: $border;
    text-align: right;
  }
}

.hours {
  text-align: center;
  font-weight: bold;
  font-size: $font-size;
  padding-left: 3px;
  position: relative;
}
.small {
  font-size: 9px;
  text-align: left;
  padding-left: 0px;
}
.project {
  display: inline-block;
  font-size: $font-size;
  width: 100%;
  text-align: center;
}

.overtime {
  line-height: $cell;
  font-size: $font-size;
  text-align: center;
}

.popoverButton {
  background: #fff;
  height: 16px;
  width: 16px;
  border-radius: 100px;
  color: #fff;
  background-color: $standard-planr-darkblue;
  cursor: pointer;
  position: absolute;
  top: -10px;
  left: 31px;

  z-index: 1000;
}
.popoverButtonOrange {
  background: #fff;
  height: 16px;
  width: 16px;
  border-radius: 100px;
  color: #fff;
  background-color: #ea561e;
  cursor: pointer;
  position: absolute;
  top: -10px;
  left: 31px;

  z-index: 1000;
}
.popoverButtonCommented {
  cursor: pointer;
  position: absolute;

  top: 0px;
  left: 29px;

  z-index: 1000;
  .isCommentedTag {
    border: 5px solid transparent;
    border-top: 5px solid #ea561e;
    border-right: 5px solid #ea561e;
    width: 5px;
    height: 5px;
  }
}

.light {
  // background-color: #fff;
  animation-name: highlight;
  animation-duration: 4s;
}
@keyframes highlight {
  0% {
    background-color: $planr-brand-color;
  }
  10% {
    background-color: #fff;
  }
  20% {
    background-color: $planr-brand-color;
  }
  30% {
    background-color: #fff;
  }
  40% {
    background-color: $planr-brand-color;
  }
  50% {
    background-color: #fff;
  }
  60% {
    background-color: $planr-brand-color;
  }
  70% {
    background-color: #fff;
  }
  80% {
    background-color: $planr-brand-color;
  }
  100% {
    background-color: #fff;
  }
}
