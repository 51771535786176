@import "modules/root/styles/planr/variables.scss";

@media (max-width: 885px) {
  .tabs {
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    :global {
      .bp3-tab-list {
        margin-left: 55px;
      }
      /* can use only UI kit class here because it applies only to active tab */
      .bp3-tab-panel {
        margin-top: 0px;
        flex: 1 1;
        display: flex;
        overflow: hidden;
        background-color: $light-planr-gray;
      }
    }
  }
}

@media (min-width: 885px) {
  .tabs {
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    :global {
      /* can use only UI kit class here because it applies only to active tab */
      .bp3-tab-panel {
        margin-top: 0px;
        flex: 1 1;
        display: flex;
        overflow: hidden;
        background-color: $light-planr-gray;
      }
    }
  }
}

.refreshbtn {
  margin-left: 30px;
}

.refreshbtn:hover {
  color: rgb(109, 167, 214);
}
