@import "modules/root/styles/planr/fonts.scss";
@import "modules/root/styles/planr/variables.scss";
.row {
  margin-bottom: 10px;
  :global {
    .ql-editor {
      height: 240px;
    }
  }
}
.column {
  width: 30%;
  position: relative;
  border-radius: 4px;
  border: 3px solid $standard-planr-gray-color;
  padding: 10px;
  max-height: 750px;
  overflow-y: scroll;
}
.columnMessages {
  margin-right: 16px;
  width: 70%;
  position: relative;
  .messagesWrapper {
    border: 3px solid $standard-planr-gray-color;
    background-color: $standard-planr-gray-color;
    padding: 10px;
    border-radius: 4px;
    max-height: 750px;
    position: relative;
    min-height: 750px;

    overflow-y: auto;
    .messagesList {
      .messageItem {
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        .messageRequest {
          border: 2px solid $planr-lightblue-color;
          background: #fff;
          width: 60%;
          position: relative;
          font-size: 12px;
          border-radius: 10px;
          padding: 5px;
          //box-shadow: 2px 2px 6px 0px $planr-lightblue-color;
          .shortInfo {
            width: 100px;
            color: $planr-lightblue-color;
          }
          .answerBtns {
            position: absolute;
            display: flex;
            right: 5px;
            top: 5px;

            .answerBtn {
              height: 15px;
              min-height: 15px;
            }
          }
        }
        .messageAnswer {
          border: 2px solid $planr-green-color;
          position: relative;
          background: #fff;
          width: 60%;
          font-size: 12px;
          border-radius: 10px;
          padding: 5px;
          // box-shadow: 2px 2px 6px 0px $planr-green-color;
          .shortInfo {
            width: 100px;
            color: $planr-green-color;
          }
          .answerBtns {
            position: absolute;
            display: flex;
            right: 5px;
            top: 5px;

            .answerBtn {
              height: 15px;
              min-height: 15px;
            }
          }
        }
        .messageText {
          width: 470px;
          max-width: 470px;
        }
      }
      .messageRow {
        display: flex;
      }
    }
  }
}

.label {
  font-size: 12px;
  margin-bottom: 5px;
}
.page {
  color: $planr-font-color;
  display: flex;
  .categories {
    width: 400px;
  }

  .selectedAgentsWrapper {
    .selectedAgentsList {
      border: 2px solid $standard-planr-gray-color;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      max-height: 300px;
      overflow-y: auto;
      .selectedRow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px;
        &:hover {
          cursor: pointer;
          background-color: $standard-planr-gray-color;
        }
      }
      .selectedRow:nth-child(odd) {
        background-color: $light-planr-gray;
        &:hover {
          cursor: pointer;
          background-color: $standard-planr-gray-color;
        }
      }
    }
  }
}
