@import "modules/root/styles/planr/fonts.scss";
@import "modules/root/styles/planr/variables.scss";

.page {
  background-color: #f5f7f8;
  margin: 16px;
  padding-left: 10px;
}

.title {
  display: flex;
  align-items: center;

  .showAccess {
    margin-left: auto;
  }
}

.userAccess {
  :global {
    .overwritten {
      background-color: rgba(54, 172, 215, 0.4);
    }
  }
}
