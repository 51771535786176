@import "./_icons.scss";
// @media (min-width: 885px) {
//   .removeBtn {
//     display: block;
//   }
// }
// @media (max-width: 885px) {
//   .removeBtn {
//     display: none;
//   }
// }
.actions {
  display: flex;
  align-items: center;
  margin-right: $standard-padding;

  @include icons;

  :global {
    .table-add-button-figma {
      margin-bottom: 0 !important;
      margin-right: $standard-padding;
    }

    .action-icon {
      margin-right: $standard-padding;
    }
  }
}
