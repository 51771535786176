@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/fonts.scss";
@import "modules/root/styles/planr/variables.scss";

@media (max-width: 885px) {
  .cardList {
    color: $planr-font-color;
    display: block;
    width: 100%;
    overflow-y: auto;
    margin: 10px 0px;
    .card {
      background-color: #fff;
    }

    .card,
    .cardSelected {
      border-radius: 4px;
      overflow-y: auto;
      margin-bottom: 10px;
      padding-right: 5px;
      display: flex;
      transition: all 0.2s ease-in-out;
      animation-name: animates;
      animation-duration: 0.5s;
      .line {
        width: 5px;
      }

      .mid {
        padding: 10px;
        flex: 1;

        .row {
          display: flex;
          width: 100%;
          margin-bottom: 5px;

          .rowItem {
            min-width: 60px;
          }
        }
        .label {
          font-size: 11px;
          color: $planr-lightblue-color;
        }

        .row {
          span {
            color: #fff;
            background-color: $planr-green-color;
            padding: 5px 10px;
            border-radius: 4px;
            font-size: 11px;
          }
        }

        .fileRow {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-bottom: 5px;
        }
      }

      .edit {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60px;
        padding: 5px;
      }
    }
  }

  .Mailtable {
    display: none;
  }

  .tools {
    display: none;
  }
  .mobileTools {
    display: flex;
    align-items: center;
  }
  .dialogTools {
    margin-top: 10px;
    transition: all 0.5s ease-in-out;
  }

  .grid {
    display: none;
  }
}

@media (min-width: 885px) {
  .grid {
    display: flex;
    flex: 1 1;
    overflow: hidden;
    flex-direction: column;
    padding-right: $standard-padding;
  }

  .tools {
    display: flex;
  }
  .mobileTools {
    display: none;
  }
  .cardList {
    display: none;
  }
  .Mailtable {
    display: block;
  }
  .dialogTools {
    display: none;
  }
}
