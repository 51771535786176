@import "modules/common/components/variables.scss";

@media (max-width: 885px) {
  .page {
    flex-direction: column;
    position: relative;

    .tabs {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    :global {
      .bp3-tab-list {
        margin-left: 55px;
      }
      .bp3-tab-panel {
        display: flex;
        flex-direction: column;
        overflow: auto;
        flex: 1 1;
        padding: 0 0px 16px 16px;
        border-left: $standard-padding solid $white;
        margin-top: 0px;
        background-color: #f5f7f8;
        margin-left: -16px;
      }
    }
  }
}

@media (min-width: 885px) {
  .page {
    flex-direction: column;
    position: relative;

    .tabs {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    :global {
      .bp3-tab-panel {
        display: flex;
        flex-direction: column;
        overflow: auto;
        flex: 1 1;
        padding: 0 16px 16px 16px;
        border-left: $standard-padding solid $white;
        margin-top: 0px;
        background-color: #f5f7f8;
        margin-left: -16px;
      }
    }
  }
}
