@import "../variables.scss";

@media (min-width: 885px) {
  .slider {
    display: flex;
    margin-top: 24px;
    margin-left: 0px;
    margin-bottom: 16px;
    align-items: center;
  }
}

@media (max-width: 885px) {
  .slider {
    display: flex;
    margin-top: 15px;
    margin-left: 0px;
    margin-bottom: 15px;
    align-items: center;
  }
}

.slider {
  .value {
    cursor: pointer;
    font-size: 18px;
    color: $standard-planr-darkblue;
    font-weight: 400;
    display: flex;
    justify-content: center;
    width: 100px;
    min-width: 100px;
    max-width: 100px;
  }

  :global {
    .month-picker {
      display: inline-block;
    }
  }
}

.step {
  cursor: pointer;

  &:hover {
    color: $standard-orange;
  }
}
