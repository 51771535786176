@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/fonts.scss";
.emptySurvey {
  color: $planr-font-color;
}
.widget {
  max-height: 300px;
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f9f9fa;
  margin-bottom: 32px;
  position: relative;

  .container {
    display: flex;
    flex-direction: row;
    flex: 1 auto;
    position: relative;
  }

  .nav {
    box-sizing: border-box;
    display: flex;
    position: absolute;
    flex-direction: row;
    width: 70px;
    align-items: center;
    justify-content: space-around;
    bottom: 25px;
    right: 35px;
    z-index: 2;
  }

  .noData {
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
  }

  :global {
    .survey {
      margin-top: -$blockHeaderHeight;
      max-width: 30%;
    }

    .survey:first-of-type {
      z-index: 1;
    }

    .block-header.widget-header {
      padding-left: 10px;
      padding-right: 10px;

      .action-icon {
        margin-left: auto;
      }
    }
  }
}

.addButton {
  margin-left: $standard-padding;
}
