@media (min-width: 885px) {
}

@media (max-width: 885px) {
  .user {
    display: none;
  }
}

.user {
  position: relative;
  //  color: $standard-planr-darkblue;
  // div {
  //     cursor: pointer;
  // }

  .settingsicon {
    margin-right: 100px;
    margin-top: -15px;
    cursor: pointer;
  }
}

.menu {
  position: absolute;
  right: 0;
}

.avatarImage {
  width: 44px;
  height: 44px;
  border-radius: 50px;
  object-fit: cover;
}
