@import "modules/spending/timesheet/components/_common.scss";
@import "_common.scss";

.lineHeader {
  top: 120px;
  position: absolute;
  color: $standard-planr-darkblue;
}

.line {
  font-size: $pt-font-size-small;

  display: flex;
  left: 0;
  padding-left: 11px;
  top: 70px;
  position: absolute;
  &.plan {
    .cell {
      color: $gray2;
    }
  }

  .cell {
    min-width: $column;
    max-width: $column;
    display: flex;
    justify-content: center;
    align-items: center;

    &.title {
      min-width: $name;
      max-width: $name;
      text-align: right;
      position: relative;
      justify-content: flex-start;
    }

    :global {
      .money {
        font-size: 11.5px;
      }
    }
  }
}
