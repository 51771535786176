@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/variables.scss";

@media (max-width: 885px) {
  .pages {
    display: none;
  }
  .cardList {
    display: block;
    width: 100%;
    overflow-y: auto;
    margin: 10px 0px;
    .card {
      background-color: #fff;
    }
    .cardSelected {
      background-color: $planr-lightgreen-color;
    }
    .card,
    .cardSelected {
      border-radius: 4px;
      overflow-y: auto;
      margin-bottom: 10px;
      padding-right: 5px;
      display: flex;
      transition: all 0.2s ease-in-out;
      animation-name: animates;
      animation-duration: 0.5s;
      .line {
        width: 5px;
      }

      .mid {
        padding: 10px;
        flex: 1;

        .row {
          display: flex;
          width: 100%;
          margin-bottom: 5px;

          .rowItem {
            min-width: 60px;
          }
        }
        .label {
          font-size: 11px;
          color: $planr-lightblue-color;
        }

        .fileRow {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-bottom: 5px;
        }
      }

      .edit {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60px;
        padding: 5px;
      }
    }
  }

  .Mailtable {
    display: none;
  }

  .tools {
    display: none;
  }
  .mobileTools {
    display: flex;
    align-items: center;
  }
  .dialogTools {
    margin-top: 10px;
    transition: all 0.5s ease-in-out;
  }
}

@media (min-width: 885px) {
  .pages {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .tools {
    display: flex;
  }
  .mobileTools {
    display: none;
  }
  .cardList {
    display: none;
  }
  .Mailtable {
    display: block;
  }
  .dialogTools {
    display: none;
  }
}

.tableHeader {
  display: flex;
}

.tableRow {
  display: flex;
  min-height: 30px;
  align-items: center;
}

.color {
  min-width: 10px;
  width: 10px;
  height: 100%;
}

.checkbox {
  width: 20px;
}

.noDataCell {
  background-color: #ebdddf;
  color: #d73636;
  display: flex;
  border-radius: 4px;
  font-weight: 700;
  width: 70px;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  height: 20px;
}
.list {
  flex: 1 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;

  .Mailtable {
    height: 100%;
  }
  .actions {
    display: flex;
    align-items: center;

    margin-right: 6px;
  }

  .addButton {
    position: absolute;
    box-shadow: none !important;
    border-radius: 0;
    width: 49px;
    z-index: 1;
  }

  :global {
    .ag-grid-wrapper {
      flex: 1 auto;
      margin: $standard-padding 0 $standard-padding 0;

      circle {
        opacity: 1;
      }
    }

    .ag-cell-value {
      font-size: 12px;
    }

    .ag-group-expanded,
    .ag-group-contracted {
      display: none !important;
    }

    .pager {
      margin-left: auto;

      :global {
        .bp3-button {
          color: $standard-planr-darkblue;
          border: 1px solid $standard-planr-gray-color;
        }

        .bp3-button:first-of-type {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        .bp3-button:last-of-type {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }

        .bp3-intent-primary {
          background-color: #1dd278;
          color: #fff;
        }
      }
    }

    .ag-theme-balham .ag-row-odd {
      background-color: $white;
    }

    .ag-theme-balham .ag-row-hover {
      background-color: $light-gray;
    }

    .ag-theme-balham .ag-row-selected {
      background-color: $light-gray;
    }
  }
}

.warning {
  background-color: sandybrown;
}
.warning:hover {
  background-color: purple;
}
@keyframes animates {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
