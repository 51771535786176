.align {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  top: calc(50% - 25px);
  left: calc(50% - 25px);
}

.localLoader {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.5);
  .loaderText {
    font-size: 14px;
    margin-bottom: 16px;
  }
}
