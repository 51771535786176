@import "modules/root/styles/theme/blueprint/variables.scss";
@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/fonts.scss";
@import "modules/root/styles/planr/variables.scss";

@media (max-width: 885px) {
  .topTools {
    display: none;
  }

  .mobileTools {
    display: flex;
    align-items: center;
  }
  .dialogTools {
    display: flex;
    align-items: center;
    margin-top: -10px;
    margin-bottom: 20px;
  }

  .mobileSum {
    display: flex;
    color: $planr-green-color;
  }
  .pages {
    display: none;
  }
  .mobileHeader {
    display: block;

    .headerText {
      max-width: 80%;
      font-size: 14px;

      .headerMoney {
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }
  .header {
    display: none;
  }
  .cardList {
    color: $planr-font-color;
    display: block;
    width: 100%;
    overflow-y: auto;
    .card {
      background-color: #fff;
    }

    .card,
    .cardSelected {
      border-radius: 4px;
      overflow-y: auto;
      margin-bottom: 10px;
      padding-right: 5px;

      transition: all 0.2s ease-in-out;
      animation-name: animates;
      animation-duration: 0.5s;
      .line {
        width: 5px;
      }

      .mid {
        padding: 10px;
        flex: 1;

        .row {
          display: flex;
          width: 100%;
          margin-bottom: 5px;

          .rowItem {
            min-width: 60px;
          }
        }
        .label {
          font-size: 11px;
          color: $planr-lightblue-color;
        }

        .row {
          span {
            color: #fff;
            background-color: $planr-green-color;
            padding: 5px 10px;
            border-radius: 4px;
            font-size: 11px;
          }
        }

        .fileRow {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-bottom: 5px;
        }
      }

      .edit {
        display: flex;
        justify-content: center;
        padding: 0px 5px 15px 5px;
      }
    }
  }

  .Mailtable {
    display: none;
  }

  .tools {
    display: none;
  }
  .mobileTools {
    display: flex;
    align-items: center;
  }

  .grid {
    display: none;
  }
}

@media (min-width: 885px) {
  .topTools {
    display: block;
  }

  .mobileSum {
    display: none;
  }
  .mobileTools {
    display: none;
  }

  .header {
    display: block;
  }
  .mobileHeader {
    display: none;
  }
  .grid {
    display: flex;
    flex: 1 1;
    overflow: hidden;
    flex-direction: column;
    padding-right: $standard-padding;
  }

  .tools {
    display: flex;
  }
  .mobileTools {
    display: none;
  }
  .cardList {
    display: none;
  }
  .Mailtable {
    display: block;
  }
  .dialogTools {
    display: none;
  }
}

.tabs {
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  :global {
    /* can use only UI kit class here because it applies only to active tab */
    .bp3-tab-panel {
      margin-top: 0px;
      padding-left: 16px;
      background-color: #f5f7f8;
      flex: 1 1;
      display: flex;
      overflow: hidden;
      padding-right: 0;
    }

    .tab-select-dropdown {
      .bp3-fill {
        padding: 10px;
        word-wrap: break-word;
        overflow: initial;
        text-overflow: initial;
        white-space: normal;
        text-align: justify;
      }
    }
  }
}

.actions {
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  right: 16px;
  top: 24px;
}
.right {
  display: flex;
  margin-right: 32px;
  align-items: center;
}

.table {
  flex: 1 1;
  overflow-y: auto;
  position: relative;
  padding-right: $standard-padding;

  :global {
    .ag-layout-auto-height .ag-center-cols-clipper {
      min-height: 0;
    }

    .money {
      position: absolute;
      left: 620px;
    }

    .order-flag {
      width: 20px;
      height: 20px;
      display: inline-block;
      margin-right: 3px;
      border-radius: 2px;
      opacity: 0.6;

      &:not(.empty):not(.batch) {
        border: 1px solid black;
      }

      &.batch {
        border: 3px solid green;
      }
    }

    .sorting {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    .tools {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 24px;
      margin-bottom: 20px;
      height: 52px;
      width: 120px;
      border-radius: 120px;
      background-color: $standard-planr-gray-color;
    }

    .grouping {
      margin-left: 5px;
    }

    .search {
      max-width: 265px;
    }

    .figma-icon {
      margin-right: $standard-padding;
    }

    svg {
      max-height: 20px;
    }
  }
}
