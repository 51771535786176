@import "modules/common/components/variables.scss";
@import "modules/root/styles/theme/blueprint/variables.scss";

@import "./main-menu.scss";
@import "./tabs-navigation.scss";
@import "./inputs.scss";
@import "./scroll-bars.scss";
@import "./headers.scss";
@import "./month-picker.scss";
@import "./tables.scss";
@import "./buttons.scss";
@import "./files.scss";
@import "../planr/fonts.scss";
@import "../planr/variables.scss";
.money {
  font-family: "GothamPro", Arial, sans-serif;
  font-size: 11px;
}

.orange {
  color: $standard-orange;
}

.mouse-select__frame {
  background: $planr-lightblue-color;
  height: 25px;
  opacity: 0.2;
}

.mouse-select__selectable {
  &.selected {
    background: $planr-lightblue-color-light;
  }
  &.active {
    background: $planr-lightblue-color-light;
    opacity: 0;
  }
}
