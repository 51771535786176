@import "modules/root/styles/theme/blueprint/variables.scss";

.list {
  width: 1330px;

  :global {
    .bp3-dialog-body {
      display: flex;
      margin-top: 0;
    }

    .ag-theme-balham {
      height: 780px;
      max-height: 780px;
      display: flex;
      flex: 1 1;
      overflow: hidden;
      flex-direction: column;
    }

    .ag-theme-balham + .ag-theme-balham {
      margin-left: 5px;
      max-width: calc(50% - 30px);
    }

    .ag-floating-bottom .bp3-icon {
      display: none;
    }
  }
}
