@import "modules/common/components/variables.scss";
@import "./common";

.day {
  padding: 0 $c-padding $c-padding $c-padding;

  .dayName {
    color: $standard-planr-darkblue;
    font-size: $font-size-standard;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .time {
    display: inline-block;
    font-weight: 400;
    min-width: 120px;
    color: #ea561e;
    margin-right: 10px;
  }

  .addButton {
    margin-left: $standard-padding;
  }

  .event {
    display: flex;
    align-items: center;
  }

  .editButton {
    margin-right: $standard-padding;
    cursor: pointer;
  }
}
