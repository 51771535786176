@import "./_common.scss";

@media (min-width: 885px) {
  .dialogLeft {
    border: 2px solid $standard-planr-gray-color;
    border-radius: 4px;
    padding: 15px 15px 0px 15px;
    min-height: 320px;
    width: 1134px;
  }

  .projectRow {
    color: $standard-planr-darkblue;
    font-family: GothamPro, Arial, sans-serif;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;

    :global {
      .bp3-icon-cross {
        margin-left: 5px;
        margin-top: 2px;
      }
    }
  }
  .projectSelectRow {
    display: flex;
    align-items: center;
  }

  .iconsRowCol {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 885px) {
  .dialogLeft {
    border: 2px solid $standard-planr-gray-color;
    border-radius: 4px;
    padding: 5px 5px 0px 5px;
    min-height: 320px;
    width: 100%;
    margin-left: -20px;
    margin-right: -20px;
  }

  .projectRow {
    color: $standard-planr-darkblue;
    font-family: GothamPro, Arial, sans-serif;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    :global {
      .bp3-icon-cross {
        margin-left: 5px;
        margin-top: 2px;
      }
    }
  }

  .projectSelectRow {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .iconsRowCol {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.dialog {
  max-width: 1134px;
  width: 100%;
  min-height: 480px;

  form {
    flex: 1 1;
    display: flex;
    flex-direction: column;
  }
}

.dialogWidth {
  width: 1750px;
  min-height: 480px;
}

.dialogRight {
  border: 2px solid $standard-planr-gray-color;
  border-radius: 4px;
  padding: 15px 15px 0px 15px;
  margin-left: 24px;
  max-width: 600px;
}
.planTitle {
  width: 100%;
  margin-bottom: 16px;
  text-align: center;
  font-size: 16px;
  color: #b9c1c7;
}
.dayType,
.projectSelection {
  max-width: 100%;
}

.dayType {
  display: flex;
  align-items: flex-end;

  :global {
    .select {
      flex: 1 1;
    }

    .figma-icon {
      margin-right: $standard-padding;
    }
  }
}

.activeIcon {
  path {
    fill: $standard-blue !important;
  }
}

.projectSelection {
  margin-top: $standard-padding;

  .justWork {
    width: 100%;
  }
}
.commentDay {
  margin-bottom: 16px;
}
.divider {
  width: 100%;
  height: 1px;
  border-bottom: 2px solid $standard-planr-gray-color;
  margin-top: $standard-padding;
  margin-bottom: $standard-padding;
}

.dayResult {
  .projectRows {
    padding: 8px;
    border: 2px dashed $standard-planr-gray-color;
    border-radius: 4px;
    margin-bottom: 15px;
  }

  .projectName {
    margin-right: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70%;
  }

  .home {
    margin-left: 10px;
    cursor: pointer;
    min-width: 22px;

    &.invisible {
      visibility: hidden;
      cursor: default;
    }
  }
}

.selectedIcon {
  path {
    fill: $standard-blue !important;
  }
}

.iconsRow {
  display: flex;
  align-items: center;
}

.hoursRow {
  display: flex;
  align-items: center;
}
.btnsRow {
  display: flex;
  align-items: center;
}

.hoursInput {
  max-width: 50px;
  min-width: 50px;
  margin-left: $standard-padding;
  margin-right: 5px;

  input[disabled] {
    color: $black;
    cursor: default;
  }
}

.minutesInput {
  max-width: 80px;
  min-width: 80px;
  margin-left: $standard-padding;
  margin-right: 5px;

  input[disabled] {
    color: $black;
    cursor: default;
  }
}
.commentInput {
  margin: 0 auto;
  margin-top: 5px;
  input[disabled] {
    color: $black;
    cursor: default;
  }
}

.commentDayInput {
  width: 329px;

  input[disabled] {
    color: $black;
    cursor: default;
  }
}

.row:nth-child(even) {
  background-color: #f7f9fa;
}
.rowHead {
  align-items: center;
  height: 37px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "GothamPro", Arial, sans-serif;
  color: $standard-planr-darkblue;
  font-size: 12px;
  display: flex;
  background-color: #c8d0d6;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.row {
  align-items: center;
  height: 37px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "GothamPro", Arial, sans-serif;
  color: $standard-planr-darkblue;
  font-size: 12px;
  display: flex;
}
.inventory {
  width: 60px;
  min-width: 50px;
  padding: 0 10px 0 15px;
}
.order {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.noWorkInfo {
  position: absolute;
  color: #e31818;
  font-size: 10px;
  left: 2px;
}
.name {
  width: 290px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $standard-planr-darkblue;
  text-align: justify;
}

.hours {
  width: 60px;
  text-align: center;
  padding: 0 15px 0 10px;
}

.bold {
  font-size: 14px;
}
