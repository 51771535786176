@import "modules/root/styles/planr/variables.scss";

.previewer {
  display: flex;
  flex: 40%;
  // min-width: 600px;
  // max-width: 760px;
  padding: 15px;
  padding-top: 0px;
  margin-top: -5px;
  background-color: $standard-planr-gray-color;
  position: relative;
  border-radius: 4px;

  :global {
    .bp3-card {
      padding: 0;
      margin: 0;
      display: flex;
      flex: 1 auto;
    }

    .scroll-pdf-viewer {
      width: 100%;
      border-radius: 4px;
    }
    // .pdf-block {

    //     height: 100%;
    //     display: flex;
    //     width: 100%;
    //     background-color: #e4e8eb;
    // }
    .previewer-btns {
      display: flex;
      position: absolute;
      right: 16px;
      top: 5px;
    }
    .image-viewer {
      width: 100%;
      margin-top: 70px;
    }
  }
}
