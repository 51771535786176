.tabs {
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .width-select.bp3-fill > span > div {
    max-width: 100px;
  }

  :global {
    /* can use only UI kit class here because it applies only to active tab */
    .bp3-tab-panel {
      margin-top: 0px;
      flex: 1 1;
      display: flex;
      overflow: hidden;
    }
    .bp3-button {
      width: 215px;
    }
    .tab-select-dropdown {
      .bp3-fill {
        padding: 10px;
        word-wrap: break-word;
        overflow: initial;
        text-overflow: initial;
        white-space: normal;
        text-align: justify;
      }
    }
  }
}
