@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/planr-scrolls.scss";
@import "modules/root/styles/planr/variables.scss";
@import "~@blueprintjs/core/src/components/button/_common.scss";
@import "~@blueprintjs/core/src/components/menu/_common.scss";
@import "../app/App.module.scss";
$submenu-padding: 5px;
$left-padding: 20px;

@media (min-width: 885px) {
  .menu {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: 0.2s ease-out;
    width: 308px;
  }
  .scroller {
    overflow-y: auto;
    overflow-x: hidden;

    @include planr-scoll();
  }
}

@media (max-width: 885px) {
  .menu {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: 0.2s ease-out;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
    width: 0px;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .scroller {
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 70px;
    @include planr-scoll();
  }
}

.menu {
  // overflow: hidden;
  // display: flex;
  // flex-direction: column;
  // transition: 0.2s ease-out;
  //box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
  .divider {
    background-color: rgba(141, 183, 219, 0.4);
  }

  :global {
    .bp3-menu:not(.root) {
      padding: 0;
      margin-left: 0;
      transition: 0.2s ease-in;
    }

    .bp3-menu-item:not(.top-level) {
      .bp3-fill {
        font-size: $font-size-small;
      }
    }

    .main-menu-item {
      position: relative;
      z-index: 3;
      width: 303px;

      .top-level .bp3-fill {
        padding: 7px 0px;
      }

      .pin1 {
        position: absolute;
        color: $planr-brand-color;
        background-color: $planr-brand-color-light;
        font-size: 10px;
        width: 21px;
        font-weight: bold;
        height: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 120px;
        top: 12px;
        border-radius: 100px;
      }
    }

    .add-order-button {
      color: $standard-orange;

      text-decoration: none;

      &:hover {
        color: rgba(0, 39, 61, 0.04);
      }
    }

    .toggle-ribbon-button {
      cursor: pointer;
      position: absolute;
      top: 15px;
      right: 45px;
      transition: 0.2s ease-in;
      z-index: 1000;
      &:hover {
        color: #ea561e;
      }
    }
  }
}
