.fileList {
  overflow: auto;
}

@media (max-width: 885px) {
  .dialog {
    width: 100%;
  }
}

@media (min-width: 885px) {
  .dialog {
    width: 850px;
  }
}
