@import "modules/common/components/variables.scss";
.wrapper {
  width: 100%;
  height: 93%;

  position: relative;
  > div {
    height: 100%;
  }

  .noData {
    position: absolute;
    top: 49%;
    left: 46.5%;
  }
}
.tableContainer {
  margin-top: 20px;

  height: 100%;
}
.table {
  height: 100%;
  display: flex;
  flex: 1 1;
  overflow: hidden;
  flex-direction: column;
  // padding-right: $standard-padding;

  :global {
    .ag-row {
      background-color: #fff;
      &:hover {
        background-color: $standard-planr-gray-color;
      }
      .ag-cell-value,
      .ag-group-value {
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
      }

      // a {
      //     color: $standard-planr-darkblue;
      //     border-bottom: 1px dotted $standard-planr-darkblue;
      // }
      .ag-cell {
        padding-right: 0 !important;
      }
    }
  }
}
.unitDescription {
  color: $standard-planr-darkblue;
  padding: 7px;
  font-size: 12px;
  max-width: 500px;
  white-space: normal;
  word-wrap: break-word;
}
.cutRow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.noDataCell {
  background-color: #ebdddf;
  color: #d73636;
  display: flex;
  border-radius: 4px;
  font-weight: 700;
  width: 70px;
  align-items: center;
  justify-content: center;
  font-size: 11px;
}

.withDataCell {
  color: #1dd278;
  background-color: #d8ede6;
  display: flex;
  border-radius: 4px;
  width: 70px;
  align-items: center;
  justify-content: center;
  cursor: help;
  font-weight: 700;
  font-size: 11px;
}

.acceptedDataCell {
  color: #28b8ec;
  background-color: rgba(54, 172, 215, 0.15);
  display: flex;
  border-radius: 4px;
  width: 70px;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: 700;
}

.tasksWrapper {
  width: 600px;
  max-height: 500px;
  padding: 10px;
  color: $standard-planr-darkblue;
  position: relative;
}

.versionsWrapper {
  width: 640px;
  max-height: 500px;
  padding: 10px;
  color: $standard-planr-darkblue;
}
.tasksList {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 480px;
  overflow-y: scroll;

  .taskItem {
    border: 2px solid rgb(223, 223, 223);
    margin-bottom: 5px;
    padding: 7px;

    .description {
      font-size: 12px;
      margin-bottom: 5px;
    }
    .taskRow {
      display: flex;
      font-size: 10px;
      margin-bottom: 5px;

      .author {
        width: 120px;
      }

      .version {
        width: 80px;
      }

      .file {
        width: 145px;
      }

      .date {
        width: 80px;
      }
      .implementer {
        width: 120px;
      }
      .statuses {
        width: 80px;
      }
    }
    .headerRow {
      font-size: 12px;
    }
  }
}

.pathWrapper {
  display: flex;
  max-width: 550px;
  min-width: 200px;
  padding: 20px;
  height: 60px;
  color: $standard-planr-darkblue;
  align-items: center;
  font-size: 12px;
  justify-content: space-between;
  position: relative;
  .pathText {
    white-space: nowrap;
    overflow-x: scroll;
    max-width: 420px;
    margin-top: 5px;
    margin-right: 20px;
    color: $standard-planr-darkblue;
    font-weight: 300;
  }
}
