@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/fonts.scss";
@import "modules/root/styles/planr/variables.scss";

.itemRow {
  width: 30%;
}

.itemRowHeaderWrap {
  display: flex;
  .itemRowHeader1 {
    margin-right: 20px;
    font-size: 12px;
    color: $planr-font-color;
    margin-bottom: 5px;
  }
  .itemRowHeader2 {
    font-size: 12px;
    color: $planr-font-color;
    margin-bottom: 5px;
  }
}

.columns {
  margin-top: 20px;
  flex: 1 1;
  flex-direction: row;
  display: flex;

  .column {
    position: relative;
    padding-left: 3px; // inputs outline
    width: 400px;
    .info {
      color: $planr-brand-color;
      font-size: 12px;
      font-weight: 400;
      display: flex;
      align-items: center;
      margin-top: -20px;
      margin-bottom: 20px;
    }
    & + .column {
      margin-left: $standard-padding * 5;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  .full {
    width: 100%;
  }

  .semicolumn {
    width: 200px;
    margin-left: $standard-padding;
  }
}
.label {
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: $planr-font-color;
}

.labelHeader {
  font-size: 12px;
  margin-bottom: 5px;
  color: $planr-font-color;
}

.stageDialog {
  display: flex;

  .stageDialogColumn:nth-child(1) {
    margin-right: 10px;
    padding-right: 0px;
    width: 445px;
  }

  .stageDialogColumn {
    border: 2px solid $standard-planr-gray-color;
    border-radius: 4px;
    padding: 10px;
    color: $planr-font-color;
  }
}
.employersList {
  border: 2px solid $standard-planr-gray-color;
  border-radius: 4px;
  margin-top: 10px;
  max-height: 450px;
  overflow-y: auto;
  .employersListItem:nth-child(even) {
    background-color: $light-planr-gray;
  }
  .employersListItem {
    padding-left: 10px;
    display: flex;
    align-items: center;

    .employersListItemName {
      width: 300px;
    }
  }
}

:global {
  .select-dropdown {
    width: 400px;
    .bp3-fill {
      word-wrap: break-word;
      overflow: initial;
      text-overflow: initial;
      white-space: normal;
      text-align: justify;
    }
    .bp3-menu {
      max-width: 400px;
      min-width: 400px;
    }
  }
}
