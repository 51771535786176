@import "../vars.scss";
@import "modules/root/styles/planr/variables.scss";

.object {
  padding: 12px 0px;
  background-color: $white;
  width: 1540px;
  transition: 0.2s ease-in;
  color: $standard-planr-darkblue;
  border-radius: 4px;
  margin-bottom: 8px;

  .label {
    display: flex;
    position: relative;
    font-size: $font-size;
    padding-left: $padding;
    font-size: 16px;
    .labelWrapper {
      width: calc(100% - 100px);
      .sumNumbersObj {
        //  margin-top: 45px;
        position: relative;
        width: 100%;
        height: 24px;
      }
      .labelContent {
        //white-space: nowrap;
        display: block;
        //width: 100%;
        //overflow: hidden;
        // text-overflow: ellipsis;
      }
    }
  }

  .collapser {
    margin-right: $collapser-padding;
  }

  .outsourcers {
    margin-top: $standard-padding;
  }

  :global {
    .order-payments {
      width: 400px;
    }

    .outsourced-spending-row {
      margin-bottom: $standard-padding;
    }

    .action-icon {
      margin-left: $cross-padding;
    }
  }
}

.spendingBody {
  :global {
    .bp3-form-group {
      margin: 0;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  padding: 16px 16px;
  background-color: $white;

  &:not(.row:first-child) {
    border-top: 1px solid $standard-planr-gray-color;
  }

  .left {
    display: flex;
    flex-direction: column;
  }

  .one {
    display: flex;
    flex-direction: row;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    width: 540px;
    max-width: 540px;
    .mainInfo {
      width: 450px;
      max-width: 450px;
    }
  }

  .actuals,
  .plan {
    td {
      vertical-align: middle;
    }
  }

  .actuals {
    .table {
      min-width: 190px;
      padding-top: 14px;
      .headerRow {
        height: 5px;
      }
      .sumBtn {
        width: 60px;
      }
      .sumTop {
        max-width: 232px;
        width: 232px;
        font-size: 12px;
        height: 12px;
        padding: 0px;
        padding-right: 12px;
      }

      .dateTop {
        max-width: 100px;
        width: 100px;
        font-size: 12px;
        height: 12px;
        padding: 0px;
      }

      .sum {
        max-width: 232px;
        width: 232px;
        font-size: 12px;
        height: 12px;
        padding-right: 12px;
        padding-bottom: 13px;
      }

      .date {
        max-width: 100px;
        width: 100px;
        font-size: 12px;
        height: 12px;
        padding-bottom: 13px;
      }
    }
  }

  .plan {
    width: 360px;
    margin-left: 25px;
    margin-right: 37px;

    .planRow {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      padding: 5px 10px;
      border-radius: 4px;
      box-shadow: 0px 2px 4px 2px rgba(34, 60, 80, 0.2);
    }
    .table {
      width: 360px;
      max-width: 360px;
      padding-top: 14px;
      margin-top: 10px;
    }
    .headerRow {
      height: 5px;
      display: flex;
      align-items: center;
    }
    .paymentBlock {
      border-radius: 4px;

      padding: 5px;

      .flexRow {
        display: flex;
        align-items: center;
        width: 270px;
        max-width: 270px;

        justify-content: space-between;
        margin-bottom: 5px;
      }
    }
    .sumBtn {
      width: 60px;
    }
    .sumTop {
      width: 80px;
      font-size: 12px;
    }

    .dateTop {
      max-width: 100px;
      width: 100px;
      font-size: 12px;
      height: 12px;
      padding: 0px;
    }

    .btn {
      width: 30px;
    }
    .sum {
      max-width: 100px;
      width: 100px;
      font-size: 12px;
      height: 12px;
      padding-right: 12px;
      padding-bottom: 13px;
    }

    .date {
      max-width: 100px;
      width: 100px;
      font-size: 12px;
      height: 12px;
      padding-bottom: 13px;
    }
  }

  .taskState {
    margin-right: 24px;

    path {
      fill: $standard-gray !important;
    }

    .active {
      path {
        fill: $standard-blue !important;
      }
    }

    .success {
      path {
        fill: $success-green !important;
      }
    }

    :global {
      .bp3-icon {
        margin-bottom: 7px;
      }
    }
  }

  .orderSum {
    display: flex;
    flex-direction: row;
    margin-top: 16px;

    label {
      font-size: 12px;
    }

    > div + div {
      margin-left: 15px;
    }

    > div {
      flex: 1 auto;
    }

    :global {
      .planr-default-input {
        margin-top: 5px;
      }
    }
  }

  .outsourcer {
    margin-top: 12px;
    display: flex;
    flex-direction: column;

    label {
      font-size: 12px;
      margin-bottom: 5px;
    }

    :global {
      .bp3-icon {
        display: flex;
        cursor: pointer;
        margin-left: $cross-padding;
      }

      .selected-option {
        width: 100%;
      }
    }
  }

  .orders {
    margin-top: $padding;
  }

  :global {
    .order-base {
      max-width: 476px;
    }

    .order-files-list {
      margin-top: 12px;
    }
  }
}

:global {
  .spendings-outsourserselect-dropdown {
    width: auto !important;

    .bp3-menu {
      overflow-y: visible;
      max-height: 190px;
      overflow-x: hidden;
    }

    .bp3-menu-item {
      width: 472px;
    }
  }

  .spendings-outsourserselect-dropdown {
    .bp3-text-overflow-ellipsis {
      white-space: normal;
    }
  }
}

.invoiceUploaded {
  display: flex;
  align-items: center;
}

.dateEvent {
  .event {
    margin-bottom: 5px;
    margin-top: 5px;
  }
  width: 100%;
}
.paymentInfo {
  width: 190px;
  max-width: 190px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.highlighted {
  // background-color: #fff;
  animation-name: highlight;
  animation-duration: 8s;
}
@keyframes highlight {
  0% {
    background-color: #f8effd;
  }
  90% {
    background-color: #f8effd;
  }
  100% {
    background-color: #fff;
  }
}
