@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/variables.scss";

// ::-webkit-scrollbar-thumb {
//     background: #369cd7;
// }

$sideWidth: 308px;
@media (min-width: 885px) {
  .leftColumn {
    width: $sideWidth;
    display: flex;
    flex-direction: column;
    z-index: 2;
    position: relative;
    margin-top: 61px;
    transition: 0.2s ease-out;
  }

  .nav_container {
    display: block;
    position: absolute;
    z-index: 3;
    height: 61px;
    background: #fff;
    transition: 0.2s ease-out;
  }
  .planr-menu-mobile {
    width: 308px;
  }
  .nav_container .checkbox {
    display: none;
  }

  .nav_container .hamburger_lines {
    display: none;
  }

  .mainLogo {
    transition: 0.2s ease-out;
    width: 100%;
    display: flex;
    min-height: 60px;
    overflow: hidden;
    align-items: center;
    margin-left: 40px;
    background-color: #fff;
  }
}

@media (max-width: 885px) {
  .leftColumn {
    width: $sideWidth;
    display: flex;
    flex-direction: column;
    z-index: 10;
    position: absolute;
    margin-top: 61px;
    transition: 0.2s ease-out;
    height: 100%;
    width: 0px;
  }

  .nav_container {
    display: block;
    position: absolute;
    z-index: 3;
    height: 61px;
    background: #fff;
    transition: 0.2s ease-out;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
    width: 0px;
  }

  .nav_container .checkbox {
    position: absolute;
    display: block;
    height: 32px;
    width: 32px;
    top: 20px;
    left: 15px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }

  .nav_container .hamburger_lines {
    display: block;
    height: 26px;
    width: 32px;
    position: absolute;
    top: 17px;
    left: 15px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .mainLogo {
    transition: 0.2s ease-out;
    width: 100%;
    display: flex;
    min-height: 60px;
    overflow: hidden;
    align-items: center;
    margin-left: 40px;
    background-color: #fff;
    width: 0px;
  }

  .planr-menu-mobile {
    width: 0px;
  }
}

.nav_container .hamburger_lines .line {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: $planr-brand-color;
}

.nav_container .hamburger_lines .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.nav_container .hamburger_lines .line2 {
  transition: transform 0.2s ease-in-out;
}

.nav_container .hamburger_lines .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}

.nav_container input[type="checkbox"]:checked ~ .hamburger_lines .line1 {
  transform: rotate(45deg);
}

.nav_container input[type="checkbox"]:checked ~ .hamburger_lines .line2 {
  transform: scaleY(0);
}

.nav_container input[type="checkbox"]:checked ~ .hamburger_lines .line3 {
  transform: rotate(-45deg);
}

.nav_container input[type="checkbox"]:checked ~ .logo {
  display: none;
}

.mainlayout {
  display: flex;
  flex-direction: row;
  flex: 1 auto;
  overflow: hidden;
  position: relative;

  :global {
    .app-user {
      position: absolute;
      top: 0;
      right: -10px;
      z-index: 1;
    }

    .mainLogo {
      display: flex;
      min-height: 60px;
      overflow: hidden;
      align-items: center;
      margin-left: 40px;
    }

    .app-version {
      margin-top: 6px;
    }
  }
}

.mainContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  overflow: hidden;
}

.leftColumn {
  .mainMenuStack {
    display: flex;
    flex: 1 auto;
    flex-direction: row;
    overflow: hidden;
  }

  :global {
    .main-menu {
      width: $sideWidth;
      padding-bottom: 0;
    }
  }
}

.preload {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.uiKitLink {
  position: fixed;
  top: 10px;
  left: 300px;
}

:global {
  .bp3-toast.bp3-intent-primary {
    background-color: $planr-lightblue-color;
  }
  .bp3-toast.bp3-intent-success {
    background-color: $planr-green-color;
  }
  .bp3-toast.bp3-intent-danger {
    background-color: $planr-red-color;
  }
}
