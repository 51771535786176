@import "modules/common/components/variables.scss";
@import "modules/root/styles/theme/blueprint/variables.scss";

$h: 100px;

@media (min-width: 885px) {
  .widgetDialog {
    height: 800px;
    max-height: 800px;
    width: 98%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 0px;
    border-radius: 4px;
    font-size: 12px;
  }
}

@media (max-width: 885px) {
  .widgetDialog {
    height: 800px;
    max-height: 800px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 0px;
    border-radius: 4px;
    font-size: 12px;
  }
}

.widget {
  max-height: 257px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f9f9fa;
  background-color: $standard-planr-gray-color;
  padding: 0 12px 12px 12px;
  border-radius: 4px;
  margin-top: -5px;

  .scroller {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  .noData {
    position: absolute;
    width: 100%;
    text-align: center;
    top: ($h/2) - $font-size-standard;
  }

  :global {
    .bp3-collapse {
      max-height: 250px;
      min-height: $h;
      background-color: #f9f9fa;
    }

    .block-header {
      padding-left: 10px;
      padding-right: 10px;

      .bp3-icon-cross {
        margin-left: auto;
      }

      .caret {
        left: auto;
        right: 10px;
      }
    }

    .message {
      //min-height: 60px;
      display: flex;
      padding: $standard-padding/2;
      align-items: center;
      color: $standard-planr-darkblue;
      background-color: #fff;
      font-weight: 400;
      font-size: 12px;
      border-bottom: 2px solid $standard-planr-gray-color;
    }

    .message:nth-child(2) {
      background-color: $standard-planr-gray-color;
    }
    .bp3-divider {
      border-bottom: none;
      margin: 0;
    }

    .checker {
      width: 50px;
      .bp3-checkbox {
        margin-bottom: 0;
        margin-left: 5px;
      }
    }

    .date {
      width: 110px;
      color: rgba(0, 39, 61, 0.4);
      margin-right: 5px;
    }

    .content {
      width: 100%;
      font-weight: 700;
      cursor: pointer;
      margin-right: 10px;

      &.read {
        font-weight: 400;
        cursor: default;
      }
    }
  }
}

.widgetDialog {
  .scroller {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  .noData {
    position: absolute;
    width: 100%;
    text-align: center;
    top: ($h/2) - $font-size-standard;
  }

  :global {
    .bp3-collapse {
      max-height: 250px;
      min-height: $h;
      background-color: #f9f9fa;
    }

    .block-header {
      padding-left: 10px;
      padding-right: 10px;

      .bp3-icon-cross {
        margin-left: auto;
      }

      .caret {
        left: auto;
        right: 10px;
      }
    }

    .message {
      min-height: 60px;
      display: flex;
      padding: $standard-padding/2;
      align-items: center;
      color: $standard-planr-darkblue;
      background-color: #fff;
      font-weight: 400;
      border-bottom: 2px solid $standard-planr-gray-color;
    }

    .message:nth-child(2) {
      background-color: $standard-planr-gray-color;
    }
    .bp3-divider {
      border-bottom: none;
      margin: 0;
    }

    .checker {
      .bp3-checkbox {
        margin-bottom: 0;
        margin-left: 5px;
      }
    }

    .date {
      width: 100px;
      color: rgba(0, 39, 61, 0.4);
      width: 100px;
      margin-right: 5px;
    }

    .content {
      width: 70%;
      font-weight: 700;
      cursor: pointer;
      margin-right: 10px;

      &.read {
        font-weight: 400;
        cursor: default;
      }
    }
  }
}
