@import "modules/common/components/variables.scss";

.tabs {
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  overflow: auto;

  :global {
    /* can use only UI kit class here because it applies only to active tab */
    .bp3-tab-panel {
      flex: 1 1;
      display: flex;
      overflow: hidden;

      &.timesheet,
      &.schedule {
        overflow: auto;
        padding: 0;
        margin-top: 0px;
      }
    }
  }
}
:global {
  .order-content_mentor-select-dropdown {
    width: 480px;
    .bp3-fill {
      word-wrap: break-word;
      overflow: initial;
      text-overflow: initial;
      white-space: normal;
      text-align: justify;
    }
    .bp3-menu {
      max-width: 480px;
      min-width: 480px;
    }
  }
}
