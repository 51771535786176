.planr-block-header {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 44px;
  @include planr-font("standard");
  background-color: $standard-planr-gray-color;
  padding: 15px 12px;
  display: flex;
  align-items: center;
  width: 99%;
  position: relative;
  font-weight: 400;
  color: $standard-planr-darkblue;
  border: 1px solid $standard-planr-gray-color;
  transition: 0.2s ease-in;

  &:hover {
    background-color: #e5e9ec;
  }

  a {
    color: white;
  }

  &.collapser {
    cursor: pointer;
    padding-left: $standard-padding * 2;
    margin-bottom: 0;

    .bp3-icon-caret-down,
    .bp3-icon-caret-up,
    .bp3-icon-chevron-down,
    .bp3-icon-chevron-up {
      position: absolute;
      left: 10px;
    }
  }

  &.light-mode {
    background-color: #eaeef1;
    color: black;
    border-color: gray;
  }

  &.flat-mode {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

#bp3-tab-panel_MainAppPageTabs_\#tab\=charts > div > div > div:nth-child(1) > span,
#bp3-tab-panel_MainAppPageTabs_\#tab\=charts > div > div > div:nth-child(2) > span,
#bp3-tab-panel_MainAppPageTabs_\#tab\=charts > div > div > div:nth-child(3) > span,
#bp3-tab-panel_MainAppPageTabs_\#tab\=charts > div > div > div:nth-child(4) > span,
#bp3-tab-panel_MainAppPageTabs_\#tab\=charts > div > div > div:nth-child(5) > span,
#bp3-tab-panel_MainAppPageTabs_\#tab\=charts > div > div > div:nth-child(6) > span,
#bp3-tab-panel_MainAppPageTabs_\#tab\=charts > div > div > div:nth-child(8) > span,
#bp3-tab-panel_MainAppPageTabs_\#tab\=charts > div > div > div:nth-child(9) > span,
#bp3-tab-panel_MainAppPageTabs_\#tab\=charts > div > div > div:nth-child(10) > span,
#bp3-tab-panel_MainAppPageTabs_\#tab\=charts > div > div > div:nth-child(11) > span,
#bp3-tab-panel_MainAppPageTabs_\#tab\=charts > div > div > div:nth-child(12) > span {
  display: none;
}

.unreaded {
  background: #ea561e;
  border-radius: 4px;
  color: #fff;
  font-size: 10px;
  padding: 4px 6px;
  margin-left: 6px;
}
.left-btns {
  position: absolute;
  top: 10px;
  right: 315px;
}

.planr-block-header-wide {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  @include planr-font("standard");
  background-color: $standard-planr-gray-color;
  padding: 12px 0px;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  font-weight: 400;
  color: $standard-planr-darkblue;
  border: 1px solid $standard-planr-gray-color;
  transition: 0.2s ease-in;

  &:hover {
    background-color: #e5e9ec;
  }

  a {
    color: $standard-planr-darkblue;
  }

  &.collapser {
    cursor: pointer;
    padding-left: $standard-padding * 2;
    margin-bottom: 0;

    .bp3-icon-caret-down,
    .bp3-icon-caret-up,
    .bp3-icon-chevron-down,
    .bp3-icon-chevron-up {
      position: absolute;
      left: 10px;
    }
  }

  &.light-mode {
    background-color: #eaeef1;
    color: black;
    border-color: gray;
  }

  &.flat-mode {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.planr-block-header-wide_lowscreen {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 44px;
  @include planr-font("standard");
  background-color: $standard-planr-gray-color;
  padding: 15px 12px;
  display: flex;
  align-items: center;
  width: 1573px;
  position: relative;
  font-weight: 400;
  color: $standard-planr-darkblue;
  border: 1px solid $standard-planr-gray-color;
  transition: 0.2s ease-in;

  &:hover {
    background-color: #e5e9ec;
  }

  a {
    color: $standard-planr-darkblue;
  }

  &.collapser {
    cursor: pointer;
    padding-left: $standard-padding * 2;
    margin-bottom: 0;

    .bp3-icon-caret-down,
    .bp3-icon-caret-up,
    .bp3-icon-chevron-down,
    .bp3-icon-chevron-up {
      position: absolute;
      left: 10px;
    }
  }

  &.light-mode {
    background-color: #eaeef1;
    color: black;
    border-color: gray;
  }

  &.flat-mode {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.collapse-percents {
  position: absolute;
  font-weight: 700;
  border-radius: 4px;
  height: 20px;
  padding: 5px 7px;
  font-size: 10px;
  left: 335px;
}

.status-doc {
  margin-top: 12px;
}

.order-total {
  position: absolute;
  top: 21px;
  left: 260px;
  font-size: 10px;
  color: $standard-planr-darkblue;
  font-weight: 700;
  border-radius: 4px;
  background-color: #e1e6e9;
  padding: 5px 7px;

  .spendings-money {
    font-size: 10px;
    color: $standard-planr-darkblue;
    font-weight: 700;
    font-family: "GothamPro", Arial, sans-serif;
  }
}

.actual-total {
  position: absolute;
  top: 21px;
  left: 620px;
  font-size: 10px;
  color: $standard-planr-darkblue;
  font-weight: 700;
  border-radius: 4px;
  background-color: #e1e6e9;
  padding: 5px 7px;

  .spendings-money {
    font-size: 10px;
    color: $standard-planr-darkblue;
    font-weight: 700;
    font-family: "GothamPro", Arial, sans-serif;
  }
}

.spendings-total {
  position: absolute;
  top: 21px;
  left: 920px;
  font-size: 10px;
  color: $standard-planr-darkblue;
  font-weight: 700;
  border-radius: 4px;
  background-color: #e1e6e9;
  padding: 5px 7px;

  .spendings-money {
    font-size: 10px;
    color: $standard-planr-darkblue;
    font-weight: 700;
    font-family: "GothamPro", Arial, sans-serif;
  }
}

.status-doc-del {
  margin-top: 36px;
}

.color-red {
  color: #e31818;
  background-color: #f4e6e6;
  font-weight: 700;
  border-radius: 4px;
  height: 20px;
  padding: 5px 7px;
  font-size: 10px;
  margin-right: 12px;
  .money {
    font-size: 10px;

    font-weight: 700;
    font-family: "GothamPro", Arial, sans-serif;
  }
}

.color-orange {
  color: $planr-brand-color-dark;
  background-color: $planr-brand-color-light;
  font-weight: 700;
  border-radius: 4px;
  height: 20px;
  padding: 5px 7px;
  font-size: 10px;
  margin-right: 12px;
}

.color-blue {
  color: $planr-lightblue-color;
  background-color: #e4f1f7;
  font-weight: 700;
  border-radius: 4px;
  height: 20px;
  padding: 5px 7px;
  font-size: 10px;
  margin-right: 12px;
}

.backdrop-color {
  background: rgba(0, 0, 0, 0.8);
}

.color-purple {
  color: #7c36d7;
  background-color: #dbd4e4;
  font-weight: 700;
  border-radius: 4px;
  height: 20px;
  padding: 5px 7px;
  font-size: 10px;
  margin-right: 12px;
}

.color-gray {
  color: $standard-planr-darkblue;
  background-color: #e1e6e9;
  font-weight: 700;
  border-radius: 4px;
  height: 20px;
  padding: 5px 7px;
  font-size: 10px;
  margin-right: 12px;
  .money {
    font-size: 10px;
    font-weight: 700;
    font-family: "GothamPro", Arial, sans-serif;
  }
}

.color-green {
  color: #1dd278;
  background-color: #d8ede6;
  font-weight: 700;
  border-radius: 4px;
  height: 20px;
  padding: 5px 7px;
  font-size: 10px;
  margin-right: 12px;
  .money {
    font-size: 10px;
    font-weight: 700;
    font-family: "GothamPro", Arial, sans-serif;
  }
}

.spendings-add-button {
  margin: 0 auto;
  width: 1488px;
}

.own-sprendings-addbtn {
  margin: 0 auto;
  margin-top: 16px;

  width: 744px;
}

.collapse-wide {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $standard-planr-gray-color;
  padding: 12px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.collapse-wide_lowscreen {
  display: flex;
  flex-direction: column;
  width: 1573px;
  background-color: $standard-planr-gray-color;
  padding: 12px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.collapse {
  display: flex;
  width: 99%;
  background-color: $standard-planr-gray-color;
  padding: 12px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.collapse-add {
  display: flex;

  margin: 0 auto;
}

.collapse-top-btns {
  background-color: $standard-planr-gray-color;
  color: black;
}

.collapse-add-rows {
  width: 740px;

  font-weight: 600;
}

.collapse-add-rows:first-child {
  margin-right: 18px;
}

.collapse-add-rows-small {
  width: 500px;
  margin-right: 18px;
  font-weight: 600;
}

.planr-tools {
  position: absolute;
  display: flex;
  top: 85px;
  right: 24px;
}

.planr-tools-arrows {
  display: flex;
  background-color: $standard-planr-gray-color;
  border-radius: 30px;
  margin-left: 8px;
}

.planr-mail-group {
  display: flex;
  background-color: $standard-planr-gray-color;
  border-radius: 30px;
  margin-left: 8px;
}

.order-filednduploader {
  margin-top: 12px;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gray-color {
  color: rgba(0, 39, 61, 0.5);
}

.flex-column {
  flex: 1 1;
  flex-direction: column;
  display: flex;
  overflow-y: auto;
  background-color: #fff;
  padding: 16px;
  border-radius: 4px;
}

.toggle-block {
  visibility: hidden;
}

.showed {
  visibility: visible;
}

.collapse-left {
  width: 820px;
  flex-direction: row;
  display: flex;
  overflow-y: auto;
  margin-right: 24px;
}

.collapse-left-header {
  width: 820px;
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  margin-right: 24px;
  color: $standard-planr-darkblue;
}

.collapse-item {
  flex-direction: row;
  display: flex;
}
.collapse-right {
  width: 696px;
  flex-direction: row;
  display: flex;
  overflow-y: auto;
}

.collapse-right-header {
  width: 696px;
  flex-direction: column;
  display: flex;
  overflow-y: auto;
  color: $standard-planr-darkblue;
}

.columns {
  flex: 1 1;
  flex-direction: row;
  display: flex;
  overflow-y: auto;
  background-color: #fff;
  padding: 16px;
  border-radius: 4px;

  .column {
    position: relative;
    padding-left: 3px; // inputs outline
    width: 400px;

    & + .column {
      margin-left: $standard-padding * 5;
    }
  }

  .semicolumn + .semicolumn {
    width: calc(50% + #{$standard-padding});
    margin-left: $standard-padding;
  }
}

.columns-vertical {
  flex-direction: column;
  display: flex;
  overflow-y: auto;
  background-color: #fff;
  padding: 16px;
  padding-top: 0px;
  border-radius: 4px;

  .column {
    position: relative;
    padding-left: 3px; // inputs outline
    width: 400px;

    & + .column {
      margin-left: $standard-padding * 5;
    }
  }

  .semicolumn + .semicolumn {
    width: calc(50% + #{$standard-padding});
    margin-left: $standard-padding;
  }
}
