@import "modules/common/components/variables.scss";
@import "modules/root/styles/theme/blueprint/variables.scss";
@import "modules/root/styles/planr/variables.scss";

.rowEdit {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  min-height: $control-height;
  padding: 5px $standard-padding/2 5px $standard-padding/2;
  border-bottom: $light-border;
  align-items: baseline;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.25);
  transform: scale(1.01);

  & > div {
    display: flex;
    align-items: center;
  }

  &:not(.headerRow):first-of-type {
    border-top: $light-border;
  }
  .iconCell {
    width: 40px;
    justify-content: center;
    align-self: center;
    margin-left: 5px;
  }
  .category {
    white-space: normal;
    text-overflow: ellipsis;
    padding-right: 10px;
    width: 160px;
  }
  .name {
    white-space: normal;
    text-overflow: ellipsis;
    padding-right: 10px;
    width: 150px;
  }
  .materials {
    white-space: normal;
    text-overflow: ellipsis;
    padding-right: 10px;
    width: 190px;

    .responses {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: baseline;
      justify-content: center;
      .addRows {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 10px 0px;
        .select {
          width: 200px;
          margin-right: 15px;
        }
      }
      .responsesRow {
        display: flex;
        width: 100%;
        align-items: center;
        margin-top: 10px;
      }
    }
  }
  .reward {
    white-space: normal;
    text-overflow: ellipsis;
    padding-right: 10px;
    width: 160px;
  }
  .price {
    white-space: normal;
    text-overflow: ellipsis;
    padding-right: 10px;
    width: 160px;
  }
  .fio {
    white-space: normal;
    text-overflow: ellipsis;
    padding-right: 10px;
    width: 180px;
  }
  .phone {
    width: 160px;
    display: flex;
    flex-direction: column;
  }
  .comment {
    white-space: normal;
    text-overflow: ellipsis;
    padding-right: 10px;
    width: 150px;
  }
  .deal {
    width: 160px;
  }

  &.headerRow {
    background-color: $light-planr-gray;
    .file {
      justify-content: center;
    }
  }

  :global {
    .bp3-input-group {
      width: 100%;
    }

    input {
      padding: 0 5px !important;
    }

    .hidden {
      display: none;
    }
  }
}

.rowTotal {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  min-height: $control-height;
  padding: 5px $standard-padding/2 5px $standard-padding/2;
  align-items: center;
  width: 100%;
  color: $standard-planr-darkblue;
  font-size: 12px;
  .iconCell {
    width: 40px;
    justify-content: center;
    align-self: center;
  }
  .category {
    white-space: normal;
    text-overflow: ellipsis;
    padding-right: 10px;
    width: 160px;
  }
  .name {
    white-space: normal;
    text-overflow: ellipsis;
    padding-right: 10px;
    width: 180px;
  }
  .materials {
    white-space: normal;
    text-overflow: ellipsis;
    padding-right: 10px;
    width: 160px;
  }
  .reward {
    white-space: normal;
    text-overflow: ellipsis;
    padding-right: 10px;
    width: 160px;
  }
  .price {
    white-space: normal;
    text-overflow: ellipsis;
    padding-right: 10px;
    width: 160px;
  }
  .fio {
    white-space: normal;
    text-overflow: ellipsis;
    padding-right: 10px;
    width: 180px;
  }
  .phone {
    width: 160px;
    display: flex;
    flex-direction: column;
  }
  .comment {
    white-space: normal;
    text-overflow: ellipsis;
    padding-right: 10px;
    width: 150px;
  }
  .deal {
    width: 160px;
  }

  .totalValue {
    border-radius: 4px;
    background-color: #d8ede6;
    font-size: 11px;
    font-weight: bold;
    padding: 5px;
    color: #28bb6f;
  }
}

.row {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  min-height: $control-height;
  padding: 5px $standard-padding/2 5px $standard-padding/2;
  border-bottom: $light-border;
  align-items: center;
  width: 100%;
  background-color: #fff;
  color: $standard-planr-darkblue;
  font-size: 12px;
  .iconCell {
    width: 40px;
    justify-content: center;
    align-self: center;
  }
  .category {
    white-space: normal;
    text-overflow: ellipsis;
    padding-right: 10px;
    width: 160px;
  }
  .name {
    white-space: normal;
    text-overflow: ellipsis;
    padding-right: 10px;
    width: 180px;
  }
  .materials {
    white-space: normal;
    text-overflow: ellipsis;
    padding-right: 10px;
    width: 160px;
  }
  .reward {
    white-space: normal;
    text-overflow: ellipsis;
    padding-right: 10px;
    width: 160px;
  }
  .price {
    white-space: normal;
    text-overflow: ellipsis;
    padding-right: 10px;
    width: 160px;
  }
  .fio {
    white-space: normal;
    text-overflow: ellipsis;
    padding-right: 10px;
    width: 180px;
  }
  .phone {
    width: 160px;
    display: flex;
    flex-direction: column;
  }
  .comment {
    white-space: normal;
    text-overflow: ellipsis;
    padding-right: 10px;
    width: 150px;
  }
  .deal {
    width: 160px;
  }

  & > div {
    display: flex;
    align-items: center;
  }

  &:not(.headerRow):first-of-type {
    border-top: $light-border;
  }

  &.headerRow {
    background-color: $light-planr-gray;
    font-size: 14px;
    .file {
      justify-content: center;
    }
  }

  :global {
    .bp3-input-group {
      width: 100%;
    }

    input {
      padding: 0 5px !important;
    }

    .hidden {
      display: none;
    }
  }
}
.headerMg {
  margin-top: 8px;
}

.filesVersion {
  padding: 10px;
  .rowVersion {
    display: flex;
    align-items: center;
    .file {
      width: calc(200px + #{$standard-padding/2});
      padding-right: $standard-padding/2;
      display: flex;
      flex: 1 1;
      flex-direction: column;
    }

    &.headerRow {
      background-color: $light-planr-gray;

      .file {
        justify-content: center;
      }
    }

    .version {
      font-size: 12px;
    }
  }
}

.dealEdit {
  padding: 5px;
  margin-bottom: 8px;
  border: 2px solid $standard-planr-gray-color;
  box-shadow: 4px 4px 4px 0px rgba(34, 60, 80, 0.2);
}

.dealNotEdit {
  min-width: 135px;
  padding: 5px;
  .dealNotEditSum {
    margin-bottom: 5px;
  }
  .dealNotEditItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3px 5px;
    font-size: 11px;
    border-radius: 4px;
    border: 2px solid $planr-lightblue-color;
    margin-bottom: 5px;
    box-shadow: 4px 4px 4px 0px rgba(34, 60, 80, 0.2);

    .dealNotEditItemDate {
      color: $planr-lightblue-color;
    }
  }
}
