@media (max-width: 885px) {
  .tools {
    display: none;
  }
  .mobileTools {
    display: flex;
    margin-top: -10px;
  }
}

@media (min-width: 885px) {
  .tools {
    display: flex;
    align-items: center;
  }
  .mobileTools {
    display: none;
  }
}

.page {
  background-color: #f7f9fa;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 24px;
  .right {
    position: absolute;
    align-items: center;
    display: flex;
    right: 40px;
    top: 20px;
  }
}
