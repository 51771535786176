@media (min-width: 885px) {
  .planr-user {
    position: relative;
    @include planr-font("standart");
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 66px;
    margin: 8px 0px;

    .user-details {
      display: flex;
      flex-direction: column;
      margin-right: 12px;

      .user-details-post {
        @include planr-font("small");
        color: #80939e;
        text-align: right;
      }
    }

    .planr-settingsicon {
      display: block;
      margin-right: 23px;
      cursor: pointer;
      transition: 0.2s ease-in;
      border-bottom: 2px solid #fff;
    }
  }
}

@media (max-width: 885px) {
  .planr-user {
    position: relative;
    @include planr-font("standart");
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 66px;
    margin: 8px 0px;

    .user-details {
      display: none;
      flex-direction: column;
      margin-right: 12px;

      .user-details-post {
        @include planr-font("small");
        color: #80939e;
        text-align: right;
      }
    }

    .planr-settingsicon {
      display: block;
      margin-right: 23px;
      cursor: pointer;
      transition: 0.2s ease-in;
      border-bottom: 2px solid #fff;
    }
  }
}

.planr-menu {
  position: absolute;
  right: 0;
}
