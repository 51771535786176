@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/fonts.scss";
@import "modules/root/styles/planr/variables.scss";
.columns {
  margin-top: 20px;
  flex: 1 1;
  flex-direction: row;
  display: flex;
  overflow-y: auto;

  .column {
    position: relative;
    padding-left: 3px; // inputs outline
    width: 400px;

    & + .column {
      margin-left: $standard-padding * 5;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  .full {
    width: 100%;
  }

  .semicolumn {
    width: 200px;
    margin-left: $standard-padding;
  }
}
