$planr-tag-background-colors: (
  neutral: rgba(0, 39, 61, 0.05),
  primary: rgba(54, 172, 215, 0.1),
  success: #d8ede6,
  danger: #ebdddf,
);

$planr-tag-text-colors: (
  neutral: $standard-planr-darkblue,
  primary: #369cd7,
  success: #1dd278,
  danger: #d73636,
);

@mixin planr-tag-color($type: "neutral") {
  background-color: map-get($planr-tag-background-colors, $type);
  color: map-get($planr-tag-text-colors, $type);
}

@mixin planr-tag-padding() {
  padding: 5px 7px;
}
