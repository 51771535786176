.planr-content-tabs-navigation {
  @include planr-font("standard");
  margin-top: 18px;

  .bp3-tab-indicator-wrapper {
    display: none;
  }

  .bp3-tab {
    margin-left: 16px;
    transition: 0.2s ease-in;
    padding: 0 20px 15px 20px;
    background: rgba(29, 210, 120, 0.1);
    border-radius: 4px;
    text-align: center;
    color: #1dd278;
  }
  .bp3-tab:hover {
    color: $planr-brand-color-dark;
  }

  .bp3-tab[aria-selected="true"] {
    position: relative;
    overflow: visible;
    color: $planr-brand-color;
  }

  .bp3-tab-list {
    padding-left: $standard-padding;
    width: 80%;
    overflow-x: auto;

    & > *:not(:last-child) {
      margin-right: 0;
      padding-right: 20px;
      padding-left: 20px;
    }
  }
}

.planr-tabs-navigation {
  $line: 2px;
  @include planr-font("standard");
  margin-top: 21px;

  .bp3-tab-indicator-wrapper {
    display: none;
  }

  .bp3-tab {
    transition: 0.2s ease-in;
    padding: 0 8px 12px 8px;
    border-bottom: $line solid white;
    text-align: center;
    color: $standard-planr-darkblue;
  }
  .bp3-tab:hover {
    color: $planr-brand-color-dark;
  }

  .bp3-tab[aria-selected="true"] {
    border-bottom: $line solid $planr-brand-color;
    position: relative;
    overflow: visible;
    color: $planr-brand-color;
  }

  .bp3-tab-list {
    // padding-left: $standard-padding;
    width: 87%;
    overflow-x: auto;

    & > *:not(:last-child) {
      margin-right: 0;
      padding-right: 12px;
      padding-left: 12px;
    }
  }
}

@media (min-width: 885px) {
  .planr-tabs-navigation-mobile {
    $line: 2px;
    @include planr-font("standard");
    margin-top: 21px;

    .bp3-tab-indicator-wrapper {
      display: none;
    }

    .bp3-tab {
      transition: 0.2s ease-in;
      padding: 0 12px 12px 12px;
      border-bottom: $line solid white;
      text-align: center;
      color: $standard-planr-darkblue;
    }
    .bp3-tab:hover {
      color: $planr-brand-color-dark;
    }

    .bp3-tab[aria-selected="true"] {
      border-bottom: $line solid $planr-brand-color;
      position: relative;
      overflow: visible;
      color: $planr-brand-color;
    }

    .bp3-tab-list {
      // padding-left: $standard-padding;
      width: 87%;

      & > *:not(:first-child) {
        margin-right: 0;
        // padding-right: 15px;
        // padding-left: 15px;
      }
    }
  }
}

@media (max-width: 885px) {
  .planr-tabs-navigation-mobile {
    $line: 2px;
    @include planr-font("standard");
    margin-top: 18px;

    .bp3-tab-indicator-wrapper {
      display: none;
    }

    .bp3-tab {
      transition: 0.2s ease-in;
      padding: 0 8px 15px 8px;
      border-bottom: $line solid white;
      text-align: center;
      color: $standard-planr-darkblue;
    }
    .bp3-tab:hover {
      color: $planr-brand-color-dark;
    }

    .bp3-tab[aria-selected="true"] {
      border-bottom: $line solid $planr-brand-color;
      position: relative;
      overflow: visible;
      color: $planr-brand-color;
    }

    .bp3-tab-list {
      padding-left: $standard-padding;
      width: 87%;
      &:first-child {
        margin-left: 40px;
        width: 250px;
        margin-bottom: 10px;
      }
      & > *:not(:first-child) {
        display: none;
      }
    }
  }
}
