@import "modules/common/components/variables.scss";
@import "modules/root/styles/theme/blueprint/variables.scss";
@import "modules/root/styles/planr/fonts.scss";
@import "modules/root/styles/planr/variables.scss";

.page {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: 100%;
  .wrapper {
    width: 100%;
    height: 100%;
  }

  :global {
    .bp3-inline .bp3-label {
      min-width: 70px;
    }
  }
}
.dialogInfo {
  color: $planr-font-color;
  width: 400px;
  padding: 15px 0px 25px 0px;
  align-items: center;
  .dialogBody {
    text-align: justify;
  }
}
.okBtn {
  margin-top: 20px;
  margin-bottom: 10px;
  width: 212px;
}

.bottomBtns {
  display: flex;
  justify-content: center;
}
