@import "modules/common/components/variables.scss";
@import "./common";

$pad: 10px;

@media (min-width: 885px) {
  .header {
    display: flex;
    align-items: center;
    min-height: 38px;
    width: 100%;
    background-color: #f7f9fa;
  }

  .tasks {
    width: $c-width + $c-padding * 2 +290px;
    transform: scale(0.92);
    padding: $c-padding;
    margin-left: -40px;
    margin-top: -15px;

    height: 659px;
  }
}

@media (max-width: 885px) {
  .header {
    display: flex;
    align-items: center;
    min-height: 38px;
    width: 100%;
    background-color: #f7f9fa;
    justify-content: center;
    margin-top: -5px;
  }

  .tasks {
    padding: 5px;
    margin-top: 10px;
    height: 659px;
    width: 100%;
  }
}

.calendar {
  background-color: $standard-planr-gray-color;
  border-radius: 4px;
  width: 100%;
  max-width: $c-width + $c-padding * 2 + 210px;
  color: rgba(0, 39, 61, 0.5);

  .header {
    :global {
      .period-slider_value {
        font-weight: normal;
      }
    }
  }
}
.tabButtons {
  width: 300px;
  display: flex;
  margin-top: -10px;
  margin-bottom: 5px;
}
