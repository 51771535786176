@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/fonts.scss";
@import "modules/root/styles/planr/variables.scss";
.details {
  .copyId {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  :global {
    .bp3-inline .bp3-label {
      min-width: 170px;
    }

    .bp3-form-content {
      width: 100%;
    }

    .bp3-form-group {
      margin-bottom: $modal-form-input-padding;
    }
  }
}
.itemRow {
  width: 185px;
}

.itemRowHeader {
  width: 195px;
  font-size: 12px;
  color: $planr-font-color;
  margin-bottom: 5px;
}

.gradesList {
  border: 2px solid $standard-planr-gray-color;
  padding: 5px;
  color: $planr-font-color;
  border-radius: 4px;
  width: 380px;
  max-height: 200px;
  min-height: 40px;

  overflow-y: auto;

  .gradesListItem {
    padding: 5px;
    &:nth-child(odd) {
      background-color: $light-planr-gray;
    }
  }
}
