@import "modules/root/styles/planr/fonts.scss";
@import "modules/root/styles/planr/variables.scss";

.page {
  width: 98%;
  padding-left: 16px;
  background-color: #f5f7f8;
  .actions {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
  }
  .right {
    display: flex;
    margin-right: 32px;
    align-items: center;
  }
  padding-top: 24px;
  .addButton {
    position: absolute;
    border-radius: 120px;
    width: 135px;
  }
  .orderRequestButton {
    position: absolute;
    border-radius: 120px;
    width: 135px;
    top: 73px;
    left: 8px;
  }

  .requestsButton {
    position: absolute;
    border-radius: 120px;
    left: 160px;
  }

  .requestCount {
    position: absolute;
    color: #fff;
    background-color: $planr-green-color;
    font-size: 10px;
    width: 21px;
    height: 21px;
    display: flex;
    align-items: center;

    justify-content: center;
    left: 260px;
    top: 15px;
    border-radius: 100px;
  }
}

.title {
  display: flex;
  align-items: center;

  .showAccess {
    margin-left: auto;
  }
}

.userAccess {
  :global {
    .overwritten {
      background-color: rgba(54, 172, 215, 0.4);
    }
  }
}

.tabButtons {
  position: absolute;
  display: flex;
  align-items: center;
  left: 450px;
}

.tabButtons2 {
  position: absolute;
  display: flex;
  align-items: center;
  left: 450px;
  top: 70px;
}
.FilesDialog {
  width: 1170px;
  height: 800px;
}
