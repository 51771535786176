$item-left-padding: 9px;
$menu-top-padding: $standard-padding * 1.5;

.main-menu-leftitem_icon {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1000;
  height: 24px;
  width: 24px;
  color: $standard-planr-darkblue;
}
.main-menu-leftitem_icon-small {
  position: absolute;
  top: 10px;
  left: 12px;
  z-index: 1000;
  height: 20px;
  width: 20px;
  color: $standard-planr-darkblue;
}
.main-menu-font {
  color: $standard-planr-darkblue;
  @include planr-font("standard");
}

.main-menu-font-red {
  @include planr-font("standard");
  color: #e31818;
}

.main-menu-left-childitem_icon {
  position: absolute;
  top: 2px;
  left: 10px;
  z-index: 1000;
  height: 24px;
  width: 24px;
}

.main-menu-item_icon {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1000;
}

.rost .planr-main-menu {
  padding: 0;
  margin: 0;

  background-color: $standard-planr-gray-color;
  height: 100%;
  width: 308px;

  .bp3-menu {
    transition: 0.2s ease-in;
    background: transparent;
    @include planr-font("standard");

    .bp3-menu-item {
      position: relative;
      color: $standard-planr-darkblue;
      padding: 5px 3px;
      padding-left: 45px;
      transition: 0.2s ease-in;

      &.bp3-intent-primary {
        background-color: #e4e8eb;
        color: $planr-brand-color;
      }

      &.bp3-intent-danger {
        background-color: transparent;
        color: $planr-brand-color;
        width: 303px;
      }

      &:hover {
        background-color: #dadde0;
      }
    }

    &:not(.root) {
      background-color: #e4e8eb;
    }
  }
}

.menu-neworder-btn {
  width: 276px;
  height: 52px;
  margin: -5px auto 25px;
}

.order-list-wrapper {
  $list-padding: calc(#{$menu-top-padding} + #{$item-left-padding});
  position: absolute;
  top: 126px;
  right: 0;
  height: calc(100% - 126px);

  .main-menu-bottom {
    border-bottom-left-radius: $standard-padding;
    border-bottom-right-radius: $standard-padding;
    background-color: blue;
    height: $standard-padding;

    margin-bottom: $standard-padding * 0.4;
  }

  .order-list {
    border-radius: $standard-padding;
    padding: $list-padding 0 $standard-padding 0;
    background-color: blue;
    color: white;

    .order-list-title {
      padding-left: $list-padding;
    }
  }
}
