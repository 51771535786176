@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/variables.scss";
@import "modules/root/styles/planr/fonts.scss";
@import "modules/root/styles/planr/variables.scss";

.actualPayment,
.dragablePayment {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;
  width: 100%;
  background-color: #fff;
  border-radius: $planr-border-radius;
  border: 2px solid $planr-lightblue-color;
  box-shadow: 4px 4px 4px 0px rgba(34, 60, 80, 0.2);

  margin-bottom: 6px;
  transition: 0.2s ease-in;
  &:hover {
    background-color: $light-planr-gray;
  }

  .isHover {
    position: relative;
    .removeBtn {
      opacity: 1;
      position: absolute;
      z-index: 1000;
      width: 100%;
      height: 100%;
      transition: ease-in 0.2s;
    }
    .name,
    .paymentName {
      transition: 0.1s ease-in;
      opacity: 0;
    }
  }
  .hideBtn {
    opacity: 0;
    position: absolute;
    z-index: 0;
    width: 100%;
    transition: ease-in 0.2s;
  }
  .infoDiv {
    position: relative;
    .name,
    .paymentName {
      transition: 0.1s ease-in;
      opacity: 1;
    }
  }
  .name,
  .paymentName {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .paymentName {
    color: $planr-green-color;
    font-size: 11px;
  }
}
.dragablePayment {
  background-color: $light-planr-gray;
  border-radius: $planr-border-radius;
  border: 2px solid $planr-lightblue-color;
  box-shadow: 6px 6px 6px 0px rgba(34, 60, 80, 0.2);
  margin-bottom: 8px;
  transition: 0.2s ease-in;
  &:hover {
    background-color: $standard-planr-gray-color;
  }
}
.actualPayment + .actualPayment {
  margin-top: $standard-padding/2;
}

.paymentDate,
.paymentSum {
  padding: 0 5px;
}

.paymentComment {
  padding: 5px;
}

.paymentDate {
  width: 90px;
  text-align: left;
  padding-bottom: 0;
}

.paymentSum {
  min-width: 130px;
  text-align: center;
  padding-bottom: 0;

  :global {
    .planr-tag {
      width: 100%;
    }
  }
}

.highlighted {
  // background-color: #fff;
  animation-name: highlight;
  animation-duration: 8s;
}
@keyframes highlight {
  0% {
    background-color: #f8effd;
  }
  90% {
    background-color: #f8effd;
  }
  100% {
    background-color: #fff;
  }
}
