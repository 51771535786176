@import "modules/common/components/variables.scss";

.requests {
  .search {
    width: 200px;
    margin-bottom: $standard-padding;
    margin-left: auto;

    :global {
      .bp3-input {
        margin-left: 0 !important;
      }
    }
  }

  .pager {
    margin-top: $standard-padding;
    display: flex;
    justify-content: flex-end;
  }

  :global {
    .planr-tag {
      font-weight: 600;
    }
  }
}

.supplierBlock {
  display: flex;
  width: 100%;
  align-items: center;

  .supplierField {
    width: 480px;
    .bp3-form-content {
      max-width: 350px;
      min-width: 350px;
    }
  }
  .supplierSwitch {
    display: flex;
    flex-direction: column;
    margin-left: 20px;

    .supplierSwitchLabel {
      font-size: 12px;
    }
  }
}

:global {
  .order-content_select-dropdown {
    width: 350px;
    .bp3-button .bp3-fill .selected-option {
      max-width: 350px;
      min-width: 350px;
    }
    .bp3-menu {
      max-width: 350px;
      min-width: 350px;
    }
  }
}
