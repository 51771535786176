@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/variables.scss";
.page {
  // flex-direction: column;
  // position: relative;
  // padding: 0px;
  // overflow-y: auto;
  .tabs {
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  :global {
    form {
      flex: 1 1;
      flex-direction: column;
      display: flex;
      overflow: hidden;
    }
    .bp3-tab-panel {
      flex: 1 1;
      overflow: auto;
      background-color: $light-planr-gray;
      margin-top: 0px;
      padding-left: 16px;
    }
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  padding-left: $standard-padding;
  margin-bottom: 10px;
}

.content {
  margin-top: 86px;
}
