@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/fonts.scss";
@import "modules/root/styles/planr/variables.scss";

@media (min-width: 885px) {
  .details {
    flex: 1 1;
    max-height: 98%;
    display: flex;
    background-color: #fff;
    border-radius: 4px;
    padding: 16px;
    margin-right: 16px;
    margin-top: 10px;
    overflow: auto;
    overflow-x: hidden;
  }

  .columns {
    flex-direction: row;
    display: flex;
    background-color: #fff;
    // overflow-y: auto;

    .column {
      position: relative;
      padding-left: 3px; // inputs outline
      width: 400px;
      .columnRow {
        flex: 1 1;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
        min-height: 62px;
      }
      .columnHeader {
        color: $standard-planr-darkblue;
        margin-bottom: 16px;
        margin-top: 10px;
      }

      .radioGroup {
        display: flex;
        flex: 1 1;
        // z-index: 1000;
        margin-bottom: 27px;

        .radioLabel {
          font-size: 12px;
          margin: 0px;
          margin-bottom: 5px;
          color: $standard-planr-darkblue;
        }
      }

      & + .column {
        margin-left: 24px;
      }
    }

    .column:nth-child(2) {
      width: 360px;
    }

    .column:nth-child(3) {
      width: 320px;
    }

    .column:nth-child(4) {
      width: 340px;
    }

    .semicolumn + .semicolumn {
      width: calc(50% + #{$standard-padding});
      margin-left: $standard-padding;
    }
  }
}

@media (max-width: 885px) {
  .details {
    flex: 1 1;
    display: flex;
    background-color: #fff;
    border-radius: 4px;
    padding-top: 16px;
    margin-top: 10px;
    overflow: auto;
    overflow-x: hidden;
  }

  .columns {
    flex-direction: row;
    display: flex;
    background-color: #fff;
    flex-direction: column;

    .column {
      position: relative;
      padding-left: 3px; // inputs outline

      .columnRow {
        flex: 1 1;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
        min-height: 62px;
      }
      .columnHeader {
        color: $standard-planr-darkblue;
        margin-bottom: 16px;
        margin-top: 10px;
      }

      .radioGroup {
        display: flex;
        flex: 1 1;
        // z-index: 1000;
        margin-bottom: 27px;

        .radioLabel {
          font-size: 12px;
          margin: 0px;
          margin-bottom: 5px;
          color: $standard-planr-darkblue;
        }
      }

      .semicolumn + .semicolumn {
        width: calc(50% + #{$standard-padding});
        margin-left: $standard-padding;
      }
    }
  }
}

.details {
  :global {
    .order-objects {
      max-height: 400px;
      margin-top: $standard-padding * 1.5;

      .bp3-html-table {
        td {
          padding: 1px;
          box-shadow: none;
        }

        th {
          padding: 0;
          text-align: center;
        }
      }
    }

    .order-files {
      margin-bottom: $standard-padding * 1.5;
    }

    .bp3-inline .bp3-label {
      white-space: nowrap;
    }

    .bp3-form-content {
      width: 100%;
    }

    .col-label {
      white-space: nowrap;
      color: $standard-planr-darkblue;
      font-size: 13px;
      font-weight: 400;
      min-height: 20px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }

    .colname-label {
      white-space: nowrap;
      color: $standard-planr-darkblue;
      font-size: 12px;
      font-weight: 400;
      height: 38px;
      display: flex;
      align-items: center;
      margin-bottom: 56px-38px+8px;
    }

    .colname-label:first-child {
      margin-top: 31px;
    }

    .finance-h3 {
      margin-top: 0;
      color: #4e4e4e;
    }
  }
}

.financeParameters {
  background-color: #f7f9fa;
  padding: 7px 10px;
  padding-bottom: 0px;

  .financeHeader {
    color: $standard-planr-darkblue;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-bottom: 20px;
    display: flex;

    :global {
      .bp3-popover-wrapper {
        margin-left: auto;
      }
    }
  }
}
.financecolumns {
  flex: 1 1 1;
  flex-direction: row;
  display: flex;
  // overflow-y: auto;

  .column {
    position: relative;
    // padding-left: 3px; // inputs outline
    width: 120px;

    & + .column {
      margin-left: 7px;
    }
  }

  .overspending {
    :global {
      .bp3-input {
        color: $planr-brand-color;
      }
    }
  }
}

.orderFilesBlock {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: -20px;
}

.orderBase {
  display: flex;
  flex-direction: row;
  margin-top: -20px;
  align-items: center;
  flex: 50% 50%;
  width: 100%;

  .half:first-of-type {
    margin-right: 16px;
  }

  :global {
    .file-uploader {
      right: 0;
      top: 2px;
      // min-width: 22px;
      // min-width: 22px;
    }

    .hidden {
      display: none;
    }

    .bp3-inline .bp3-label {
      white-space: nowrap;
    }

    // .date-picker {
    //     width: 110px;
    // }
  }
}

.half {
  width: 100%;
  padding-bottom: 0px;
}

.checkbox {
  :global {
    .bp3-label {
      display: none;
    }
  }
}

.information {
  @include planr-font();
  padding: $standard-padding;
  background-color: white;

  h4 {
    margin-top: 0;
  }
}

.hasActualDiff {
  cursor: pointer;
}

.agentsSum {
  margin-bottom: 5px;
  margin-left: 212px;
  font-size: 11px;
  height: 15px;
  color: $standard-planr-darkblue;

  .agentsSumColor {
    margin-left: 5px;
    color: #13a8df;
  }
}
