@import "modules/root/styles/theme/blueprint/variables.scss";

.list {
  width: 1090px;

  :global {
    .bp3-dialog-body {
      display: flex;
      margin-top: 0;
    }

    .ag-theme-balham {
      height: 400px;
      max-height: 400px;
      display: flex;
      flex: 1 1;
      overflow: hidden;
      flex-direction: column;
    }
  }
}

.statusesFilter {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 16px;
  .status {
    padding: 3px 10px;
    font-size: 12px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
}
