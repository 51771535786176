@import "modules/common/components/variables.scss";

@media (min-width: 885px) {
  .tabs {
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    :global {
      /* can use only UI kit class here because it applies only to active tab */
      .bp3-tab-panel {
        flex: 1 1;
        display: flex;
        overflow: hidden;
        background-color: #f7f9fa;
        margin-top: 0px;
      }
    }
  }
  .page {
    display: flex;
    flex: 1 auto;
    overflow: auto;
    padding-left: 10px;

    .right {
      display: flex;
      flex-direction: column;
      width: 370px;
      margin-left: 12px;
      margin-right: 14px;
      margin-top: 16px;
    }
  }

  .left_topbtns {
    display: flex;
    align-items: center;
    position: absolute;
    left: 317px;
    top: 24px;
  }
}

@media (max-width: 885px) {
  .tabs {
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    :global {
      /* can use only UI kit class here because it applies only to active tab */
      .bp3-tab-panel {
        flex: 1 1;
        display: flex;
        overflow: hidden;
        background-color: #f7f9fa;
        margin-top: 0px;
        ::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
  .page {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 auto;
    padding: 5px;
    padding-top: 15px;
    overflow-x: hidden;
    ::-webkit-scrollbar {
      display: none;
    }
    .right {
      display: flex;
      flex-direction: column;
      width: 100%;

      margin-top: 16px;
    }
  }

  .left_topbtns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 48px;
    column-gap: 8px;
  }
}

.page {
  .left {
    display: flex;
    flex-direction: column;
    flex: 1 auto;
    position: relative;
  }

  &.compact {
    overflow: hidden;
  }

  :global {
    .main-widget {
      margin-bottom: $standard-padding;
    }

    .own-spendings-widget {
      max-width: 490px;
    }

    .outsourcer-details {
      display: flex;
      flex: 1 auto;
      overflow: hidden;
      position: relative;

      form {
        display: flex;
        flex-direction: column;
        flex: 1 auto;
        overflow: hidden;
        margin-bottom: 10px;
      }

      .bp3-dialog-body {
        overflow: auto;
        padding-right: 10px;
      }

      .bp3-dialog-footer {
        padding-right: 10px;
      }

      .outsourcer-details-projects {
        background-color: transparent;
      }
    }
  }
}
