@import "modules/common/components/variables.scss";
@import "modules/root/styles/theme/blueprint/variables.scss";
@import "modules/root/styles/planr/variables.scss";

@media (min-width: 885px) {
  .orderTitle {
    position: absolute;
    display: flex;
    width: 100%;
    top: 15px + 45px; // .tabs-navigation + .bp3-tab
    font-size: 32px;
    font-weight: bold;
    padding: 32px 24px;
    background-color: $light-planr-gray;
    color: $standard-planr-darkblue;
    align-items: center;
  }

  .tabs {
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    :global {
      /* can use only UI kit class here because it applies only to active tab */
      .bp3-tab-panel {
        flex: 1 1;
        overflow: auto;
        margin-top: 100px;
        background-color: $light-planr-gray;

        &:not(.flat) {
          padding-left: 24px;
        }
      }

      .payment-row-highlighted {
        background-color: $selected-row-color;
      }

      .tools {
        position: absolute;
        top: 110px;
        right: $standard-padding;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        z-index: 1;

        :global {
          .figma-icon {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
@media (max-width: 885px) {
  .orderTitle {
    position: absolute;
    display: flex;
    width: 100%;
    top: 15px + 45px; // .tabs-navigation + .bp3-tab
    font-size: 32px;
    font-weight: bold;
    padding: 32px 24px;
    background-color: $light-planr-gray;
    color: $standard-planr-darkblue;
    align-items: center;
    margin-top: 5px;
  }
  .tabs {
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    :global {
      /* can use only UI kit class here because it applies only to active tab */
      .bp3-tab-panel {
        flex: 1 1;
        overflow: auto;
        margin-top: 100px;
        background-color: $light-planr-gray;

        &:not(.flat) {
          padding-left: 24px;
        }
      }

      .payment-row-highlighted {
        background-color: $selected-row-color;
      }

      .tools {
        position: absolute;
        top: 110px;
        right: $standard-padding;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        z-index: 1;

        :global {
          .figma-icon {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}

.orderManage {
  font-family: Gotham Pro, Arial, Helvetica, sans-serif;
  form {
    flex: 1 1;
    flex-direction: column;
    display: flex;
    overflow: hidden;
  }

  .mailsPanel {
    padding-bottom: 0;
    margin-top: 0;
  }

  .reminder {
    color: $standard-gray;
    font-size: $font-size-large;
    line-height: $font-size-large * 2;

    span {
      color: $error-red;
    }
  }

  :global {
    .buttons {
      width: auto;
      display: inline-block;
      margin-bottom: $standard-padding;
      margin-right: $standard-padding;

      @include buttons-width();

      .buttons-left {
        padding-right: 5px;
      }
    }
  }
}

.orderTitle {
  position: absolute;
  display: flex;
  width: 100%;
  top: 15px + 45px; // .tabs-navigation + .bp3-tab
  font-size: 32px;
  font-weight: bold;
  padding: 32px 24px;
  background-color: $light-planr-gray;
  color: $standard-planr-darkblue;
  align-items: center;
}

// .tabs {
//   flex: 1 auto;
//   display: flex;
//   flex-direction: column;
//   overflow: hidden;

//   :global {
//     /* can use only UI kit class here because it applies only to active tab */
//     .bp3-tab-panel {
//       flex: 1 1;
//       overflow: auto;
//       margin-top: 100px;
//       background-color: $light-planr-gray;

//       &:not(.flat) {
//         padding-left: 24px;
//       }
//     }

//     .payment-row-highlighted {
//       background-color: $selected-row-color;
//     }

//     .tools {
//       position: absolute;
//       top: 110px;
//       right: $standard-padding;
//       display: flex;
//       justify-content: flex-end;
//       align-items: center;
//       z-index: 1;

//       :global {
//         .figma-icon {
//           margin-right: 1rem;
//         }
//       }
//     }
//   }
// }

.buttons {
  display: flex;
  flex-direction: row;
  padding-left: $standard-padding;

  .message {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1 auto;
    padding-top: $standard-padding;
    padding-left: $standard-padding;
    color: $red2;
  }
}
