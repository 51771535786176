.tabs-navigation {
  $line: 3px;

  margin-top: 47px;

  .bp3-tab-indicator-wrapper {
    display: none;
  }

  .bp3-tab {
    padding: 0 20px 0 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
    border-top: $line solid transparent;
    font-size: 13px;
  }

  .bp3-tab[aria-selected="true"] {
    border-top: $line solid $standard-blue;
    border-right: $line solid $standard-blue;
    border-left: $line solid $standard-blue;
    border-bottom: $line solid $white;
    margin-bottom: -$line;
    font-weight: bold;
    position: relative;
    overflow: visible;

    &:before {
      content: "";
      background-image: url("./left-tab.png");
      position: absolute;
      background-color: #fff;

      width: 20px;
      height: 20px;
      left: -20px;
      bottom: -$line;
      z-index: 1;
    }

    &:after {
      content: "";
      background-image: url("./right-tab.png");
      position: absolute;
      background-color: #fff;

      width: 20px;
      height: 20px;
      right: -20px;
      bottom: -$line;
      z-index: 1;
    }
  }

  .bp3-tab-list {
    border-bottom: $line solid $standard-blue;
    padding-left: $standard-padding;

    & > *:not(:last-child) {
      margin-right: 0;
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  .bp3-tab-panel {
    padding: 0 $standard-padding $standard-padding $standard-padding;
  }
}
