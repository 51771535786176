@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/variables.scss";
.ipd {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  overflow: hidden;
  padding-right: 16px;

  .add {
    height: 43px;
    display: flex;
    flex-direction: row;
    align-items: center;

    cursor: pointer;

    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: $standard-padding;

      :global {
        .action-icon {
          margin-right: $standard-padding * 0.6;
        }
      }
    }
  }

  :global {
    .copy {
      margin-left: auto;
    }
  }
}
.label {
  color: $standard-planr-darkblue;
  margin-bottom: 3px;
}
.buttons {
  position: fixed;
  bottom: 0;
  right: 0;
  width: auto;
  display: flex;

  background-color: #fff;
  width: 100%;

  .buttonsLeft {
    margin-left: 330px;
  }
}
.rowAgent {
  margin-top: 5px;
  display: flex;
  .agentList {
    align-items: center;
    width: 480px;
  }
  .agentListlabel {
    font-size: 12px;
    margin-bottom: 3px;
    color: $standard-planr-darkblue;
  }
}

.listOrderAgents {
  overflow-y: scroll;
  padding-top: 165px;
  padding-bottom: 35px;
  overflow-x: hidden;
}
.attached {
  position: fixed;
  top: 165px;
  width: 100%;
  background: #f6f8f9;
}
.workItemContainer {
  max-height: 300px;
  overflow-y: auto;
  border: 2px solid rgb(213, 213, 213);
  padding: 7px;
  border-radius: 4px;
}
.changeWorkListBtn {
  margin: 10px auto;
}
.workItem {
  display: flex;
  align-items: center;
}

.workDialog {
  color: $planr-font-color;
  width: 850px;
  .icon {
    width: 50px;
    padding-left: 10px;
  }
  .sum {
    width: 150px;
  }

  .date {
    width: 120px;
  }

  .comment {
    width: 450px;
  }
  .headerItem {
    margin-bottom: 10px;
    font-weight: 700;
    display: flex;
    align-items: center;
  }
  .paymentItem:nth-child(even) {
    background-color: $light-planr-gray;
  }
  .paymentItem {
    padding: 5px 0px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
