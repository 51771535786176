@import "modules/common/components/variables.scss";
@import "./common";

@media (min-width: 885px) {
  .widget {
    width: 100%;
    padding: $c-padding;
    margin-left: 11px;
    margin-right: 8px;
    margin-bottom: 8px;
  }
}

@media (max-width: 885px) {
  .widget {
    width: 100%;
    padding: $c-padding;
    margin-left: 4px;
    margin-right: 8px;
    margin-bottom: 8px;
  }
}

.names {
  display: flex;
  flex-direction: row;

  .dayName {
    text-align: center;
    min-width: $day-width;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    text-transform: uppercase;
  }
}

.wrapper {
  display: flex;
  flex-direction: row;
  flex: 1 auto;
  flex-wrap: wrap;
  border-radius: $radius;
}
