@import "modules/common/components/variables.scss";
@import "modules/root/styles/theme/blueprint/variables.scss";
@import "modules/root/styles/planr/fonts.scss";
@import "modules/root/styles/planr/variables.scss";
@media (min-width: 885px) {
  .materialsRow {
    width: 400px;
  }
  .errorLabel {
    display: none;
  }
  .mainInfo {
    .materialsRow {
      width: 400px;
    }
    min-width: 270px;
    max-width: 270px;
    margin-right: 16px;
    .materialAddContainer {
      position: relative;
      margin-top: 15px;
      .materialAddBtn {
        position: absolute;
        top: -12px;
        right: 0px;
        height: 30px;
        width: 54px;
      }
    }
  }
}

@media (max-width: 885px) {
  .materialsRow {
    width: 300px;
  }
  .errorLabel {
    display: flex;
    color: $planr-red-color;
  }
  .materialsDialog {
    width: 365px;
  }
  .details {
    flex-direction: column;
    position: relative;
    width: 100%;
    background-color: #fff;
    .mainInfo {
      display: flex;
      flex-direction: column;
      width: 100%;

      .materialAddContainer {
        position: relative;
        margin-top: 15px;
        .materialAddBtn {
          position: absolute;
          top: -12px;
          right: 0px;
          height: 30px;
          width: 54px;
        }
      }
    }
    :global {
      .bp3-dialog-body {
        flex-direction: column;
        display: flex;
      }
    }
  }
}

.isLegalBtns {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.isShortInfoBtns {
  display: flex;
  justify-content: flex-end;
  margin-left: 100px;
}
.headerRow {
  display: flex;
  align-items: center;
  margin-bottom: -15px;
}
.filterBtns {
  position: absolute;
  top: -65px;
  left: 200px;
  display: flex;
}
.details {
  position: relative;
  margin-top: -20px;
  background-color: #fff;

  :global {
    .bp3-dialog-body {
      display: flex;
      & > div {
        flex: 1 0;
      }
    }

    .bp3-inline .bp3-label {
      min-width: 130px;
    }

    .bp3-form-content {
      width: 100%;
    }

    .rating-stars {
      line-height: 30px;
    }

    .bp3-form-group {
      margin-bottom: $modal-form-input-padding;
    }
  }

  .formRow {
    display: flex;
    :global {
      .bp3-form-group + .bp3-form-group {
        margin-left: 10px;
      }

      .bp3-form-group {
        flex: 1 auto;
      }
    }
  }

  .headerLabel {
    color: $standard-planr-darkblue;
    margin-bottom: 16px;
    margin-top: -2px;
    display: flex;
  }

  .mainInfo {
    display: flex;
    flex-direction: column;

    .selectRow {
      width: 290px;
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-bottom: $modal-form-input-padding;

      :global {
        .bp3-popover-wrapper {
          margin-right: 2px;
        }
      }
    }
  }

  .h3Label {
    color: $planr-font-color;
  }
  .workTypes {
    display: flex;
    flex-direction: column;
    min-width: 270px;
    max-width: 270px;
    margin-right: 16px;
    .headerLabel {
      color: $standard-planr-darkblue;
      font-size: 14px;
      font-family: Gotham Pro, Arial, Helvetica, sans-serif;
      margin-bottom: 16px;
      display: flex;
    }
    .selectRow {
      width: 290px;
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-bottom: $modal-form-input-padding;

      :global {
        .bp3-popover-wrapper {
          margin-right: 2px;
        }
      }
    }
  }

  .projects {
    display: flex;
    flex-direction: column;
    margin-left: $standard-padding;
    min-width: 560px;
    max-width: 835px;
    max-height: 850px;
    overflow-y: auto;
    background-color: #fff;
    @include planr-font();

    .label {
      color: $standard-planr-darkblue;
      font-size: 14px;
      font-family: Gotham Pro, Arial, Helvetica, sans-serif;
      margin-bottom: 16px;
      display: flex;
      margin-top: -6px;
    }

    .projectsHeader {
      background-color: #e8fbf1;
      margin-top: 30px;

      td {
        box-shadow: none !important;
      }
    }

    :global {
      .bp3-card {
        flex: 1 auto;
        margin-bottom: $modal-form-input-padding;
        padding: $standard-padding $standard-padding/2 0 $standard-padding/2;
        overflow-y: auto;
      }

      table {
        width: 100%;

        &:first-child {
          tbody tr:first-child td {
            box-shadow: none;
          }
        }
        tr:nth-child(even) {
          background-color: #f7f9fa;
        }

        th {
          white-space: nowrap;
          font-size: 13px;
        }

        td,
        th {
          color: $standard-planr-darkblue;
          @include planr-font();
        }

        label {
          font-size: $pt-font-size;
        }
      }
    }
  }
}

.credentials {
  display: flex;
  flex-direction: column;

  & > div {
    width: 100%;
  }

  .printCredentials {
    display: flex;

    button {
      width: 100%;
      text-align: center;
      height: calc(100% - #{$modal-form-input-padding});
    }
  }

  :global {
    .bp3-divider {
      margin-left: 0;
    }
  }
}

.materials {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  .addRows {
    display: flex;
    align-items: center;
    margin: 10px 0px;
    .select {
      width: 200px;
      margin-right: 15px;
    }
  }
  .responsesRow {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 10px;
  }
}

.materialsList {
  display: flex;
  flex-direction: column;
  padding: 5px;
  width: 100%;
  border-radius: 4px;
  background-color: #edf0f2;
  color: $planr-font-color;
  overflow-y: auto;
  min-height: 38px;
  max-height: 150px;
}

.smallDialog {
  color: $standard-planr-darkblue;
}
.workDialog {
  width: 800px;
}

.workItemContainer {
  max-height: 500px;
  overflow-y: auto;
  border: 2px solid rgb(213, 213, 213);
  padding: 7px;
  border-radius: 4px;
}
.changeWorkListBtn {
  margin: 10px auto;
}
.workItem {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.workItem:hover {
  background-color: #f8f8f8;
}
