@import "modules/spending/timesheet/components/_common.scss";
@import "./_common";
@import "modules/root/styles/planr/variables.scss";
.row {
  :global {
    .schedule-user-name {
      min-width: $name;
      max-width: $name;
      align-items: baseline;
      position: relative;
      border-bottom: 1px solid rgba(0, 39, 61, 0.05);
      .employer-name > span {
        color: $standard-planr-darkblue;
      }
    }

    .schedule-cell {
      min-width: $column;
      max-width: $column;
    }

    .buttons {
      position: absolute;
      top: 0;
      margin: 0;
      right: 5px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .bp3-icon {
        margin-left: $standard-padding;
      }
    }
  }

  form {
    display: flex;
    flex-direction: row;
    position: relative;
  }

  &.highlighted {
    .half {
      background-color: #edf2f5 !important;
    }

    :global {
      .schedule-cell.vacation,
      .schedule-cell.decrease,
      .schedule-cell.per-hour,
      .schedule-user-name {
        background-color: #edf2f5 !important;
      }
    }
  }

  &.edited {
    transform: scale(1.01);
    z-index: 1000;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.25);
    background-color: #fff !important;
    .half {
      background-color: #fff !important;
    }
    :global {
      .schedule-cell.vacation,
      .schedule-cell.decrease,
      .schedule-cell.per-hour,
      .schedule-user-name {
        background-color: #fff !important;
      }
    }
  }
}

$height: 30px;

.cell {
  display: flex;
  flex-direction: column;
  height: $height * 2;
  align-items: center;
  justify-content: center;
  border-right: 1px solid rgba(0, 39, 61, 0.05);
  background-color: #f7f9fa;
  color: rgba(0, 39, 61, 0.4);
}

.half {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &:last-of-type {
    background-color: $white;
  }
}

.actual {
  color: $standard-planr-darkblue;
  font-weight: bold;
}

.highlighter {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;

  background-color: transparentize($standard-orange, 0.8);
}

.bonus {
  position: relative;
  display: flex;
  flex: 1 1;
  padding: 0;
  margin-top: -2px;

  .correctionWrapper {
    position: absolute;
    top: calc(100% - 15px);

    background-color: transparent;
    padding: $standard-padding;
    padding-top: 0;
    left: -1rem;

    .correction {
      background-color: $white;
      padding: $standard-padding / 2;
      width: $column * 2;
    }
  }
}

.vacations {
  display: flex;
  flex-direction: row;
}

.bonusWorked {
  .bonusWorkedRowHeader {
    display: flex;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .bonusWorkedRow {
    display: flex;
  }
  .bonusWorkedCell {
    width: 150px;
  }

  .bonusWorkedCellPercent {
    width: 150px;
    color: rgba(0, 39, 61, 0.4);
  }
  padding: 10px;
  font-size: 12px;
  color: $planr-font-color;
}
