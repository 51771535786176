@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/fonts.scss";
.fileList {
  margin-bottom: $standard-padding / 2;
  color: $planr-font-color;
  padding: 5px 10px 5px 10px;
  flex: 1 auto;
  :global {
    .file-link,
    .planr-dnduploader {
      max-width: none;
    }

    .planr-tag {
      display: block;
      width: fit-content;
      align-self: center;
    }
  }
  h3 {
    margin-top: 0;
  }

  .uploadedfilesList {
    max-height: 130px;
    overflow: auto;
  }
}
