@import "modules/common/components/variables.scss";
@import "modules/root/styles/planr/variables.scss";
$colorW: 3px;
$cellP: 8px;


@media (min-width: 885px) {
    .title {
        font-size: 14px;
    
        margin-top: -17px;
    }
    .mobileClose{
        display: none;
    }
}

@media (max-width: 885px) {
    .title {
        font-size: 14px;
    }

    .mobileClose {
        display: block;
        margin-left: 16px;
        margin-top: -10px;
        margin-bottom: 10px;

        > svg{
            color:$planr-brand-color;
            font-weight: bold;
        }
    }
    
}

.list {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1 auto;
    transition: 0.2s ease-out;
    z-index: 1000;
    width: 570px;
}

.filter {
    margin: $standard-padding * 0.5 $standard-padding * 2 $standard-padding $standard-padding * 2;

    input {
        border-radius: 7px;
    }
}

.scroller {
    overflow-y: auto;
    flex: 1 1;
    margin-left: 12px;
}


.table {
    width: 100%;
    z-index: 1000;
    border-collapse: collapse;
    tr {
        border-bottom: 1px solid $standard-planr-gray-color

        &:hover {
            background-color: #f7f9fa;
        }

        &.available {
            td {
                cursor: pointer;
            }
        }
    }
    th {
        text-align: left;
        font-weight: normal;
    }

    th,
    td {
        font-size: $font-size-small;
        padding: 5px 0 5px $cellP;
        height: 37px;
    }

    .even {
        td {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }

    .selected {
        td {
            background-color: $standard-planr-gray-color
        }
    }

    .number {
        width: 30px;
        position: relative;
        padding-left: $colorW + $cellP;
    }

    .name,
    .status {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        max-width: 110px;
    }

    .status {
        width: 90px;
    }
}

.color {
    position: absolute;
    width: $colorW;

    bottom: 0;
    top: 0;
    left: 0;
}
